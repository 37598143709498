<template>
  <div>
    <div v-for="(applicant, index) in v.applicantForm3.$model" :key="index">
      <h2 class="h1 text-dark text-center mb-12">Educational Information for applicant {{ index + 1 }}</h2>

      <b-form-group>
        <template v-slot:label>
          Did you attend High School?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-high_school_graduate`"
              v-model="applicant.high_schools.attend"
              :value="true"
            />
            <span />
            Yes
          </label>
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-high_school_graduate1`"
              v-model="applicant.high_schools.attend"
              :value="false"
            />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.high_schools.attend === true">
        <b-form-group>
          <template v-slot:label>
            Name of High School
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.high_schools.name"
          />
        </b-form-group>

        <b-form-group>
          <template v-slot:label>
            Dates of Attendance (Start)
          </template>
          <date-picker
            :id="`${index}_high_schools.start_date_of_attendance`"
            v-model="applicant.high_schools.start_date_of_attendance"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of Attendance (End)
          </template>
          <date-picker
            :id="`${index}_high_schools.end_date_of_attendance`"
            v-model="applicant.high_schools.end_date_of_attendance"
            :min="applicant.high_schools.start_date_of_attendance"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Graduated
          </template>
          <div class="radio-inline">
            <label class="radio">
              <input
                type="radio"
                :name="`${index}-high_school_graduated`"
                v-model="applicant.high_schools.graduated"
                :value="true"
              />
              <span />
              Yes
            </label>
            <label class="radio">
              <input
                type="radio"
                :name="`${index}-high_school_graduated1`"
                v-model="applicant.high_schools.graduated"
                :value="false"
              />
              <span />
              No
            </label>
          </div>
        </b-form-group>
        <div v-if="applicant.high_schools.graduated === true">
          <b-form-group>
            <template v-slot:label>
              Year Graduated
            </template>
            <b-form-input
              type="text"
              v-mask="defaultMask.year"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.high_schools.year_graduated"
            />
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              Degree
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.high_schools.degree"
            />
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              Concentration
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.high_schools.concentration"
            />
          </b-form-group>
        </div>
        <b-form-group>
          <template v-slot:label>
            Honors or Awards
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.high_schools.honors"
          />
        </b-form-group>
      </div>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-form-group>
        <template v-slot:label>
          Did you attend college?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input type="radio" :name="`${index}-college_graduate`" v-model="applicant.colleges.attend" :value="true" />
            <span />
            Yes
          </label>
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-college_graduate1`"
              v-model="applicant.colleges.attend"
              :value="false"
            />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.colleges.attend === true">
        <b-form-group>
          <template v-slot:label>
            Name of College
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.colleges.name"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of Attendance (Start)
          </template>
          <date-picker
            :id="`${index}_colleges.start_date_of_attendance`"
            v-model="applicant.colleges.start_date_of_attendance"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of Attendance (End)
          </template>
          <date-picker
            :id="`${index}_colleges.end_date_of_attendance`"
            :min="applicant.colleges.start_date_of_attendance"
            v-model="applicant.colleges.end_date_of_attendance"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            College Graduated
          </template>
          <div class="radio-inline">
            <label class="radio">
              <input
                type="radio"
                :name="`${index}-college_graduated`"
                v-model="applicant.colleges.graduated"
                :value="true"
              />
              <span />
              Yes
            </label>
            <label class="radio">
              <input
                type="radio"
                :name="`${index}-college_graduated1`"
                v-model="applicant.colleges.graduated"
                :value="false"
              />
              <span />
              No
            </label>
          </div>
        </b-form-group>
        <div v-if="applicant.colleges.graduated === true">
          <b-form-group>
            <template v-slot:label>
              College Year Graduated
            </template>
            <b-form-input
              type="text"
              v-mask="defaultMask.year"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.colleges.year_graduated"
            />
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              College Degree
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.colleges.degree"
            />
          </b-form-group>
        </div>
        <b-form-group>
          <template v-slot:label>
            College Concentration
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.colleges.concentration"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            College Additional Concentration
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.colleges.additional_concentration"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            College Honors or Awards
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.colleges.honors"
          />
        </b-form-group>
      </div>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-form-group>
        <template v-slot:label>
          Did you attend graduate school?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-graduate_school`"
              v-model="applicant.graduate_schools.attend"
              :value="true"
            />
            <span />
            Yes
          </label>
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-graduate_school1`"
              v-model="applicant.graduate_schools.attend"
              :value="false"
            />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.graduate_schools.attend === true">
        <b-form-group>
          <template v-slot:label>
            Name of Graduate School
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.graduate_schools.name"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of Attendance (Start)
          </template>
          <date-picker
            :id="`${index}graduate_schools.start_date_of_attendance`"
            v-model="applicant.graduate_schools.start_date_of_attendance"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of Attendance (End)
          </template>
          <date-picker
            :id="`${index}graduate_schools.end_date_of_attendance`"
            v-model="applicant.graduate_schools.end_date_of_attendance"
            :min="applicant.graduate_schools.start_date_of_attendance"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Graduate School Graduated
          </template>
          <div class="radio-inline">
            <label class="radio">
              <input
                type="radio"
                :name="`${index}-graduate_school_graduated`"
                v-model="applicant.graduate_schools.graduated"
                :value="true"
              />
              <span />
              Yes
            </label>
            <label class="radio">
              <input
                type="radio"
                :name="`${index}-graduate_school_graduated1`"
                v-model="applicant.graduate_schools.graduated"
                :value="false"
              />
              <span />
              No
            </label>
          </div>
        </b-form-group>
        <div v-if="applicant.graduate_schools.graduated === true">
          <b-form-group>
            <template v-slot:label>
              Year Graduated
            </template>
            <b-form-input
              type="text"
              v-mask="defaultMask.year"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.graduate_schools.year_graduated"
            />
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              Graduate School Degree
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.graduate_schools.degree"
            />
          </b-form-group>
        </div>
        <b-form-group>
          <template v-slot:label>
            Graduate School Concentration
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.graduate_schools.concentration"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Graduate School Honors or Awards
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.graduate_schools.honors"
          />
        </b-form-group>
      </div>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-form-group>
        <template v-slot:label>
          Do you have any professional licenses?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-proffesional_license`"
              v-model="applicant.licenses.have"
              :value="true"
            />
            <span />
            Yes
          </label>
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-proffesional_license1`"
              v-model="applicant.licenses.have"
              :value="false"
            />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.licenses.have === true">
        <b-form-group>
          <template v-slot:label>
            Year License obtained
          </template>
          <b-form-input
            type="text"
            v-mask="defaultMask.year"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.licenses.year"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Type of License
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.licenses.license_type"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Date of Expiration of License
          </template>
          <date-picker :id="`${index}_licenses.date`" v-model="applicant.licenses.date"></date-picker>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../../helpers/api.service';

export default {
  name: 'EducationalForm',
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    applicants: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      defaultKeys: {
        high_schools: {},
        colleges: {},
        graduate_schools: {},
        licenses: {},
      },
    };
  },
  created() {
    this.v.applicantForm3.$model.forEach((item, index) => {
      const params = {
        elasticsearch: true,
        per: Math.pow(10, 12),
        where: {
          admin_user_id: this.applicants[index].id,
        },
      };

      Object.keys(this.defaultKeys).forEach((key) => {
        ApiService.query(key, { params }).then((res) => {
          this.v.applicantForm3.$model[index][key] = res.data.data.length
            ? {
                ...res.data.data[0].attributes,
                id: res.data.data[0].id,
                type: res.data.data[0].type,
              }
            : this.v.applicantForm3.$model[index][key];
        });
      });
    });
  },
};
</script>
