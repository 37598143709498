<template>
  <b-card class="card-custom" body-class="p-0">
    <!--begin::Wizard 5-->
    <div v-if="!loading" class="wizard wizard-5 d-flex flex-column flex-lg-row flex-row-fluid p-xxl-12">
      <!--begin::Aside-->
      <b-form class="wizard-custom wizard-aside d-flex flex-column flex-row-auto w-100 w-lg-400px mr-lg-12">
        <!--begin::Aside Top-->
        <div class="d-flex flex-column-fluid flex-column">
          <!--begin: Wizard Nav-->
          <div class="wizard-nav d-flex d-flex justify-content-center">
            <!--begin::Wizard Steps-->
            <KTWizardStepsList>
              <template v-for="(kstep, kindex) in steps">
                <KTWizardStepsItem
                  :order-number="kindex + 1"
                  :key="kindex"
                  :title="kstep.title"
                  :description="kstep.description"
                  :is-checked="appStepStatus > kindex"
                  :disabled="kstep.disabled"
                  :is-current="appStepStatus === kindex"
                >
                  <template v-slot:btn>
                    <step-btns
                      :show-skip="kstep.showSkip"
                      :current-step="appStepStatus"
                      :step="kindex"
                      @clicked:continue="openStep"
                      @clicked:edit="openStep"
                    ></step-btns>
                  </template>
                </KTWizardStepsItem>
              </template>
            </KTWizardStepsList>
            <!--end::Wizard Steps-->
          </div>
          <!--end: Wizard Nav-->
        </div>
        <!--end::Aside Top-->
      </b-form>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="wizard-content d-flex flex-column flex-row-fluid p-8 p-lg-0">
        <!--begin::Form-->
        <div
          class="wizard-content-inner d-flex justify-content-center flex-row-fluid h-lg-600px pt-8 pb-30 py-lg-40 px-8 px-lg-16"
          :style="{ backgroundImage: `url(${content.bg})` }"
        >
          <div class="text-center">
            <div v-if="appStepStatus < steps.length">
              <h1 class="mb-8">{{ steps[appStepStatus].title }}</h1>
              <h4>{{ steps[appStepStatus].contentDesc }}</h4>
            </div>

            <div v-else>
              <div v-if="isResubmit()" class="mt-10">
                <h1 class="mb-8">Resubmit Application</h1>
                <h4>
                  Please re-submit the application <br />
                  after making sure you have done all corrections!
                </h4>
                <b-btn
                  variant="primary"
                  v-cs-loading="resubmiting"
                  size="sm"
                  @click="reSubmitTheApplication"
                  block
                  class="font-weight-bolder"
                >
                  Resubmit
                </b-btn>
              </div>

              <div v-else>
                <div class="mb-8">
                  <i class="text-success fas fa-check-circle" :style="{ fontSize: '50px' }" />
                </div>
                <h1 class="mb-8">The form was completed!</h1>
                <h4>Thank you!</h4>
                <router-link :to="{ name: 'cooperatives-index' }">
                  <b-btn variant="primary" size="sm" block class="font-weight-bolder">
                    Finish
                  </b-btn>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!--end::Form-->
      </div>
      <!--end::Content-->
      <!--begin::Step 1-->

      <application-intro
        v-if="insideTheStep && step == 0"
        :cooperative="cooperative"
        @onSaveAndClose="saveClose"
        @onFinish="() => (appStepStatus === 0 ? saveClose(true) : saveClose(false))"
      />

      <!--begin::Step 2-->
      <application-create-personal-info
        v-if="insideTheStep && step == 1"
        @close="saveClose"
        @finish="() => (appStepStatus === 1 ? saveClose(true) : saveClose(false))"
        :applicant="application"
        :app-id="applicationId"
        :cooperative="cooperative"
      />

      <!--begin::Step 3-->
      <application-create-financial-info
        v-if="insideTheStep && step == 2"
        @close="saveClose"
        @finish="() => (appStepStatus === 2 ? saveClose(true) : saveClose(false))"
        :applicant="application"
        :app-id="applicationId"
      />

      <!--begin::Step 4-->
      <application-create-trnsactional-info
        v-if="insideTheStep && step == 3"
        @close="saveClose"
        @finish="() => (appStepStatus === 3 ? saveClose(true) : saveClose(false))"
        :applicant="application"
        :cooperative="cooperative"
        :app-id="applicationId"
      />

      <!--begin::Step 5-->
      <application-docs
        v-if="insideTheStep && step == 4"
        @onSaveAndClose="saveClose"
        @onFinish="() => (appStepStatus === 4 ? saveClose(true) : saveClose(false))"
        :applicationData="application"
        :cooperative="cooperative"
        :app-id="applicationId"
      />

      <!--begin::Step 6-->
      <application-payments
        v-if="insideTheStep && step == 5"
        @onSaveAndClose="saveClose"
        @onFinish="() => (appStepStatus === 5 ? saveClose(true) : saveClose(false))"
        :applicantData="user"
        :applicationData="application"
        :app-id="applicationId"
      />
    </div>
    <div v-else class="position-relative p-40">
      <spinner bg-transparent />
    </div>
    <!--end::Wizard 5-->
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApplicationPayments from '@/components/application/wizard/payments/Main.vue';
import ApplicationIntro from '@/components/application/wizard/Introduction/Main.vue';
import ApplicationCreatePersonalInfo from '@/components/application/wizard/personal-info/Main';
import ApplicationCreateFinancialInfo from '@/components/application/wizard/financial-info/Main';
import ApplicationCreateTrnsactionalInfo from '@/components/application/wizard/transactional/Main';
import StepBtns from '@/components/cooperative/wizard/StepBtns';
import ApplicationDocs from '@/components/application/wizard/docs/Main.vue';
import ApiService from '@/helpers/api.service';

export default {
  name: 'ApplicationsCreate',
  components: {
    StepBtns,
    ApplicationCreatePersonalInfo,
    ApplicationCreateFinancialInfo,
    ApplicationCreateTrnsactionalInfo,
    ApplicationIntro,
    ApplicationPayments,
    ApplicationDocs,
  },
  data() {
    return {
      resubmiting: false,
      content: { bg: '/media/svg/illustrations/visuals.svg' },
      loading: true,
      step: 0,
      insideTheStep: false,
      steps: [
        {
          title: 'Introduction',
          description: 'Introduction, Documents Needed, Supplementals',
        },
        {
          title: 'Personal Information',
          description: 'Applicants, Personal Info, Educational, Residential, Employment, Misc',
        },
        {
          title: 'Financial Information',
          description: 'Total Assets, Total Liabilities, Annual Income, Monthly Expenses, Other Financial Information',
        },
        {
          title: 'Transactional Information',
          description:
            "Apartment, Intended Use for Apartment, Purchase Price, Seller, Seller Attorney, Seller's Broker, Applicant's Broker Contact Information, Purchase Attorney Contact Information",
        },
        {
          title: 'Documents',
          description: 'Document List Review',
        },
        {
          title: 'Payment',
          description: 'Payment',
        },
      ],
      application: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    cooperative() {
      return { ...this.get(this.user, 'cooperative.data', {}) };
    },
    appStepStatus() {
      return this.application.step_status || 0;
    },
    applicationId() {
      return this.get(this.user, 'application.data.id', null);
    },
  },
  methods: {
    ...mapActions({
      createApplication: 'applications/create',
      updateApplication: 'applications/update',
      fetchApplication: 'applications/show',
      resubmit: 'applications/resubmit',
      resubmitAfter45: 'applications/resubmitAfter45',

      generatePdf: 'applications/generatePdf',
      generateFullPdf: 'applications/generateFullPdf',
    }),

    isResubmit() {
      const con1 = this.appStepStatus >= 6;
      const con2 =
        this.get(this.application, 'days_to_left', null) != null &&
        this.get(this.application, 'days_to_left', null) <= 0;
      const con3 = this.get(this.application, 'status', null) == 'Application Incomplete';

      return con1 && (con2 || con3);
    },
    reSubmitTheApplication() {
      let action = null;

      if (this.application.status == 'Application Incomplete') action = this.resubmit;

      if (this.application.days_to_left != null && this.application.days_to_left <= 0 && this.application.rejected)
        action = this.resubmitAfter45;

      // action = this.resubmit;

      if (action == null) return;

      this.resubmiting = true;

      const params = { id: this.applicationId };

      action(params)
        .then((result) => {
          this.generatePdfDocs();
          this.$router.push({ name: 'applications-index' });
        })
        .finally((err) => {
          this.resubmiting = false;
        });
    },
    openStep(step) {
      this.step = step;
      this.insideTheStep = true;
    },
    saveClose(finish = false) {
      this.insideTheStep = false;

      if (finish) {
        this.application.step_status++;

        ApiService.put(`applications/${this.applicationId}`, {
          step_status: this.application.step_status,
        }).then(() => {
          this.getApplicantData();
        });
      } else {
        this.getApplicantData();
      }
    },
    getCooperativeData(type) {
      const id = this.$route.params.cooperativeId;
      if (!id) return;
      this.loading = true;
      this.getCooperative(id).finally(() => (this.loading = false));
    },
    async getApplicantData() {
      this.loading = true;
      await this.fetchApplication(this.applicationId)
        .then((response) => {
          this.application = response.data.attributes;
        })
        .finally(() => (this.loading = false));
    },
    async generatePdfDocs() {
      await this.generatePdf(this.applicationId);
      await this.generateFullPdf(this.applicationId);
    },
  },
  async mounted() {
    await this.getApplicantData();

    const status = this.application.status;
    const days = this.application.days_to_left;

    if (
      !(days != null && days <= 0 && this.application.rejected) &&
      status &&
      status != 'In Progress' &&
      status != 'Application Incomplete'
    ) {
      return this.$router.push({ name: 'applications-index' });
    }
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/pages/wizard/wizard-5';
</style>
