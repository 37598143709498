<template>
  <div>
    <div v-for="(applicant, index) in v.applicantForm6.$model" :key="index">
      <h2 class="h1 text-dark text-center mb-12">Miscellaneous Information for applicant {{ index + 1 }}</h2>

      <b-form-group>
        <template v-slot:label>
          Are you a Legal Resident ?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input type="radio" :name="`${index}-legal_resident`" v-model="applicant.legal_resident" :value="true" />
            <span />
            Yes
          </label>
          <label class="radio">
            <input type="radio" :name="`${index}-legal_resident1`" v-model="applicant.legal_resident" :value="false" />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <b-form-group>
        <template v-slot:label>
          Do you have Military Experience ?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input type="radio" :name="`${index}-military`" v-model="applicant.militaries.have" :value="true" />
            <span />
            Yes
          </label>
          <label class="radio">
            <input type="radio" :name="`${index}-military1`" v-model="applicant.militaries.have" :value="false" />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.militaries.have === true">
        <b-form-group>
          <template v-slot:label>
            Branch Of Service
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.militaries.branch_of_service"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Start Year Of Service
          </template>
          <b-form-input
            type="text"
            v-mask="defaultMask.year"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.militaries.start_year_of_service"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            End Year Of Service
          </template>
          <b-form-input
            type="text"
            v-mask="defaultMask.year"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.militaries.end_year_of_service"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Type Of Discharge
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.militaries.type_of_discharge"
          />
        </b-form-group>
      </div>
      <b-form-group>
        <template v-slot:label>
          Have you ever been convicted of a crime ?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input type="radio" :name="`${index}-convicted_crime`" v-model="applicant.crimes.have" :value="true" />
            <span />
            Yes
          </label>
          <label class="radio">
            <input type="radio" :name="`${index}-convicted_crime1`" v-model="applicant.crimes.have" :value="false" />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.crimes.have === true">
        <b-form-group>
          <template v-slot:label>
            Type Of Conviction
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.crimes.type_of_conviction"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Type Of Conviction Of:
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.crimes.type_of_convicted_of"
          />
        </b-form-group>
      </div>
      <b-form-group>
        <template v-slot:label>
          Are you a Diplomat ?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input type="radio" :name="`${index}-diplomat`" v-model="applicant.diplomat" value="true" />
            <span />
            Yes
          </label>
          <label class="radio">
            <input type="radio" :name="`${index}-diplomat1`" v-model="applicant.diplomat" value="false" />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <b-form-group>
        <template v-slot:label>
          Have you ever been Evicted ?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input type="radio" :name="`${index}-evicted`" v-model="applicant.evicteds.have" :value="true" />
            <span />
            Yes
          </label>
          <label class="radio">
            <input type="radio" :name="`${index}-evicted1`" v-model="applicant.evicteds.have" :value="false" />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.evicteds.have === true">
        <b-form-group>
          <template v-slot:label>
            Date of eviction
          </template>
          <date-picker :id="`${index}_misc.evicteds.date`" v-model="applicant.evicteds.date"></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Explanation of evictions/lawsuits
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.evicteds.explanation"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../../helpers/api.service';

export default {
  name: 'MiscForm',
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    applicants: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      defaultKeys: {
        militaries: {},
        crimes: {},
        evicteds: {},
      },
    };
  },
  created() {
    this.v.applicantForm6.$model.forEach((item, index) => {
      const params = {
        elasticsearch: true,
        per: Math.pow(10, 12),
        where: {
          admin_user_id: this.applicants[index].id,
        },
      };

      Object.keys(this.defaultKeys).forEach((key) => {
        ApiService.query(key, { params }).then((res) => {
          this.v.applicantForm6.$model[index][key] = res.data.data.length
            ? {
                ...res.data.data[0].attributes,
                id: res.data.data[0].id,
                type: res.data.data[0].type,
              }
            : this.v.applicantForm6.$model[index][key];
        });
      });
    });
  },
};
</script>
