<template>
  <div>
    <h2 class="h1 text-dark text-center mb-12">Income (Per Year)</h2>
    <div class="font-italic">
      Please list your income, if any, in the following categories.
    </div>
    <div v-for="(applicant, index) in v.applicantForm3.$model" :key="`applicants-${index}`" class="position-relative">
      <h3 class="text-dark mb-8 mt-8">Income for applicant {{ index + 1 }}</h3>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Income
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="text"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.income"
            :state="validateState(v.applicantForm3.$each[index].income)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Bonus
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="text"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.bonus"
            :state="validateState(v.applicantForm3.$each[index].bonus)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            401(k) or any other securities
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="text"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.securities_amount"
            :state="validateState(v.applicantForm3.$each[index].securities_amount)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Social Security
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="text"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.social_security"
            :state="validateState(v.applicantForm3.$each[index].social_security)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            K-1 Income
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="text"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.k_income"
            :state="validateState(v.applicantForm3.$each[index].k_income)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Dividends
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="text"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.dividents"
            :state="validateState(v.applicantForm3.$each[index].dividents)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Investment property or properties
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="text"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.investment_properties"
            :state="validateState(v.applicantForm3.$each[index].investment_properties)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Any Other Income
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="text"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.other_incomes"
            :state="validateState(v.applicantForm3.$each[index].other_incomes)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
    </div>
  </div>
</template>

<script>
import CentToDollarInput from '../../../form/CentToDollarInput';
export default {
  name: 'AnnualIncomeForm',
  components: { CentToDollarInput },
  props: {
    v: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
