<template>
  <div>
    <h2 class="h1 text-dark text-center mb-12">Intended Use for Apartment</h2>
    <h3 class="text-dark mb-4">Instrument</h3>
    <div v-if="!v.applicantForm2.$model.instruments.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm2.$model.instruments"
      :key="`instruments${index}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            What instrument do you play?
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.name"
            :state="validateState(v.applicantForm2.instruments.$each[index].name)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'instruments', item.id, 'useof')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('instruments', 'useof')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Do you plan on making any alterations to the Apartment</h3>
    <div v-if="!v.applicantForm2.$model.alterations.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm2.$model.alterations"
      :key="`alterations${index}`"
      class="position-relative"
    >
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            Describe alterations
            <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.description"
            :state="validateState(v.applicantForm2.alterations.$each[index].description)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'alterations', item.id, 'useof')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('alterations', 'useof')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Subletting</h3>
    <div v-if="!v.applicantForm2.$model.subtenants.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm2.$model.subtenants"
      :key="`subtenants${index}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Proposed subtenant's name
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.name"
            :state="validateState(v.applicantForm2.subtenants.$each[index].name)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'subtenants', item.id, 'useof')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('subtenants', 'useof')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Pets</h3>
    <div v-if="!v.applicantForm2.$model.pets.length" class="font-italic">
      Empty
    </div>
    <b-row v-for="(item, index) in v.applicantForm2.$model.pets" :key="`pets_${index}`" class="position-relative">
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Number of Pets
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.number"
            :state="validateState(v.applicantForm2.pets.$each[index].number)"
            v-mask="defaultMask.zip"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Type of Pets
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.type_of_pets"
            :state="validateState(v.applicantForm2.pets.$each[index].type_of_pets)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Breed of Pets
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.breed"
            :state="validateState(v.applicantForm2.pets.$each[index].breed)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Type of future pets
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.type_of_future_pets"
            :state="validateState(v.applicantForm2.pets.$each[index].type_of_future_pets)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'pets', item.id, 'useof')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('pets', 'useof')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Will there be any additional occupants living in this Apartment</h3>
    <div v-if="!v.applicantForm2.$model.occupants.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm2.$model.occupants"
      :key="`occupants${index}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Name of Occupant
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.name"
            :state="validateState(v.applicantForm2.occupants.$each[index].name)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Relationship of occupant
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.relationship"
            :state="validateState(v.applicantForm2.occupants.$each[index].relationship)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            Criminal Convictions(s)
            <span class="text-danger">*</span>
          </template>
          <div class="radio-inline">
            <label class="radio">
              <input
                type="radio"
                v-model="item.criminal_conviction"
                :state="validateState(v.applicantForm2.occupants.$each[index].criminal_conviction)"
                :value="true"
              />
              <span />
              Yes
            </label>
            <label class="radio">
              <input
                type="radio"
                v-model="item.criminal_conviction"
                :state="validateState(v.applicantForm2.occupants.$each[index].criminal_conviction)"
                :value="false"
              />
              <span />
              No
            </label>
          </div>
        </b-form-group>
      </b-col>
      <b-col lg="12" v-if="item.criminal_conviction === true">
        <b-form-group>
          <template v-slot:label>
            Type of criminal conviction
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.type_of_criminal_conviction"
            :state="validateState(v.applicantForm2.occupants.$each[index].type_of_criminal_conviction)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            Diplomatic Status
            <span class="text-danger">*</span>
          </template>
          <div class="radio-inline">
            <label class="radio">
              <input
                type="radio"
                v-model="item.dimplomatic_status"
                :state="validateState(v.applicantForm2.occupants.$each[index].dimplomatic_status)"
                :value="true"
              />
              <span />
              Yes
            </label>
            <label class="radio">
              <input
                type="radio"
                v-model="item.dimplomatic_status"
                :state="validateState(v.applicantForm2.occupants.$each[index].dimplomatic_status)"
                :value="false"
              />
              <span />
              No
            </label>
          </div>
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            Defendant in lawsuit(s)
            <span class="text-danger">*</span>
          </template>
          <div class="radio-inline">
            <label class="radio">
              <input
                type="radio"
                v-model="item.defendant_in_lawsuit"
                :state="validateState(v.applicantForm2.occupants.$each[index].defendant_in_lawsuit)"
                :value="true"
              />
              <span />
              Yes
            </label>
            <label class="radio">
              <input
                type="radio"
                v-model="item.defendant_in_lawsuit"
                :state="validateState(v.applicantForm2.occupants.$each[index].defendant_in_lawsuit)"
                :value="false"
              />
              <span />
              No
            </label>
          </div>
        </b-form-group>
      </b-col>
      <b-col lg="12" v-if="item.defendant_in_lawsuit === true">
        <b-form-group>
          <template v-slot:label>
            Defendant in lawsuit(s) explanation
            <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.explanation"
            :state="validateState(v.applicantForm2.occupants.$each[index].explanation)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'occupants', item.id, 'useof')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('occupants', 'useof')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>
  </div>
</template>

<script>
import ApiService from '@/helpers/api.service';

export default {
  name: 'IntendedAppartmentForm',
  props: {
    v: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultassets: {
        instruments: {
          name: '',
        },
        alterations: {
          description: '',
        },
        subtenants: {
          name: '',
        },
        pets: {
          number: '',
          type_of_pets: '',
          breed: '',
          type_of_future_pets: '',
        },
        occupants: {
          name: '',
          relationship: '',
          criminal_conviction: false,
          type_of_criminal_conviction: '',
          dimplomatic_status: false,
          defendant_in_lawsuit: false,
          explanation: '',
        },
      },
    };
  },
  computed: {
    applicantListOptions() {
      if (this.applicants) {
        const data = this.applicants.map((applicant, index) => ({
          text: `Applicant ${index + 1}`,
          value: applicant.id,
        }));
        data.push({
          text: `Joint`,
          value: null,
        });
        return data;
      }
      return [];
    },
  },
  methods: {
    addItem(group) {
      const defItem = Object.assign({}, this.defaultassets[group]);
      this.v.applicantForm2.$model[group].push(defItem);
    },
    deleteItem(index, group, id) {
      this.v.applicantForm2.$model[group].splice(index, 1);

      if (id) {
        ApiService.delete(`${group}/${id}`);
      }
    },
  },
};
</script>
