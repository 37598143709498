<template>
  <div>
    <h2 class="h1 text-dark text-center mb-12">Applicants</h2>
    <b-row v-for="(applicant, index) in v.applicantForm.$model" :key="index" class="position-relative">
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            First Name
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="applicant.first_name"
            :state="validateState(v.applicantForm.$each[index].first_name)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Last Name
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="applicant.last_name"
            :state="validateState(v.applicantForm.$each[index].last_name)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Email
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="email"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :disabled="index === 0"
            required
            v-model="applicant.email"
            :state="validateState(v.applicantForm.$each[index].email)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        v-if="index > 0"
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteApplicant(index, applicant.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>

    <div class="text-right">
      <b-btn @click="addNewApplicant" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>
  </div>
</template>

<script>
import ApiService from '@/helpers/api.service';

export default {
  name: 'AppliactionsForm',
  props: {
    v: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    addNewApplicant() {
      this.v.applicantForm.$model.push({
        first_name: '',
        last_name: '',
        email: '',
      });
    },
    deleteApplicant(index, id) {
      if (confirm('Are you sure you want to delete this applicant ? This action is irreversible')) {
        this.v.applicantForm.$model.splice(index, 1);
        if (id) {
          ApiService.delete(`applicants/${id}`).then((result) => {
            this.$emit('remove', index);
          });
        }
      }
    },
  },
};
</script>
