<template>
  <KTWizardStepContent :step-number="4" title="Documents">
    <template v-slot:save-btn>
      <b-btn variant="light-primary" size="lg" @click="onCloseAndSave()">
        Save and Exit
      </b-btn>
    </template>
    <template v-slot:nav>
      <KTWizardStepContentNavList>
        <KTWizardStepContentNavItem
          :orderNumber="1"
          title="Document List Review"
          :is-checked="stepStatus > 0"
          :is-current="stepStatus === 0"
        />
      </KTWizardStepContentNavList>
    </template>

    <template v-slot:body>
      <div v-if="stepStatus === 0">
        <h2 class="h1 text-dark text-center mb-12">Document List Review</h2>
        <!-- Field -->
        <b-col cols="12" class="mb-5">
          <div class="bg-white rounded pt-6 pb-1 px-6">
            <div class="accordion accordion-light accordion-toggle-arrow" role="tablist">
              <b-card no-body>
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="card-title" v-b-toggle.accordion-1>
                    <i class="flaticon-folder" />
                    General documents
                  </div>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="general-documents" role="tabpanel">
                  <b-card-body>
                    <table class="table table-notopborder">
                      <tbody>
                        <template v-for="(doc, name) in documentsData">
                          <tr :key="name" :class="{ 'pointer-events-none': doc.loading }">
                            <td>
                              <div class="font-weight-600 font-size-14">
                                {{ doc.name }}
                                <span v-if="doc.required" class="text-danger">*</span>
                              </div>
                            </td>
                            <td class="text-right">
                              <a
                                v-if="doc.file && typeof doc.file == 'string'"
                                :href="doc.file"
                                class="mr-5"
                                target="_blank"
                              >
                                <i class="flaticon2-download"></i>
                              </a>
                              <a @click="focusFileInput(`general_doc_${name}`)" href="javascript:;">{{
                                doc.file ? 'Reupload' : 'Upload'
                              }}</a>
                              <input
                                type="file"
                                style="display: none;"
                                :ref="`general_doc_${name}`"
                                accept="application/pdf"
                                @change="handleFileUploaded($event, 'general', doc, name)"
                              />
                            </td>
                          </tr>
                          <tr v-if="doc.loading" :key="name + 'loader'">
                            <td class="p-0" colspan="2">
                              <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body v-for="(applicant, $index) in applicantList" :key="applicant.id">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="card-title" v-b-toggle="`accordion-${$index + 2}`">
                    <i class="flaticon-folder" />
                    Applicants Documents {{ $index + 1 }}
                  </div>
                </b-card-header>
                <b-collapse
                  :id="`accordion-${$index + 2}`"
                  visible
                  :accordion="`application-documents-${$index}`"
                  role="tabpanel"
                >
                  <b-card-body>
                    <table class="table table-notopborder">
                      <tbody>
                        <template v-for="(doc, name) in applicantsDocs">
                          <tr :key="name">
                            <td>
                              <span class="font-weight-600 font-size-14">
                                {{ doc.name }}
                                <span v-if="doc.required" class="text-danger">*</span>
                              </span>
                            </td>
                            <td></td>
                            <td class="text-right">
                              <a
                                v-if="
                                  (applicant[name] && typeof applicant[name] == 'string') ||
                                    get(applicant, name, []).length > 0
                                "
                                :href="applicant[name]"
                                class="mr-5"
                                target="_blank"
                              >
                                <i class="flaticon2-download"></i>
                              </a>
                              <a @click="focusFileInput(`applicant_${applicant.id}_doc_${name}`)" href="javascript:;">
                                {{
                                  (applicant[name] && typeof applicant[name] == 'string') ||
                                  get(applicant, name, []).length > 0
                                    ? 'Reupload'
                                    : 'Upload'
                                }}
                              </a>
                              <input
                                type="file"
                                style="display: none;"
                                :ref="`applicant_${applicant.id}_doc_${name}`"
                                accept="application/pdf"
                                @change="handleFileUploaded($event, 'applicant', applicant, name)"
                              />
                            </td>
                          </tr>
                          <tr v-if="applicant[`${name}_loading`]" :key="`${$index}_${name}_loading`">
                            <td class="p-0" colspan="3">
                              <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body v-if="supplementals.length">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="card-title" v-b-toggle.accordion-supplementary-docs>
                    <i class="flaticon-folder" />
                    Supplementary documents
                  </div>
                </b-card-header>
                <b-collapse visible id="accordion-supplementary-docs" accordion="supplementary-docs" role="tabpanel">
                  <b-card-body>
                    <table class="table table-notopborder">
                      <tbody>
                        <template v-for="(doc, index) in supplementalDocs">
                          <tr :key="index" :class="{ 'pointer-events-none': doc.loading }">
                            <td>
                              <div class="font-weight-600 font-size-14">
                                {{ doc.name + doc.prefix }}
                                <span class="text-danger">*</span>
                              </div>
                            </td>
                            <td class="text-right">
                              <a
                                v-if="doc.file && typeof doc.file == 'string'"
                                :href="doc.file"
                                class="mr-5"
                                target="_blank"
                              >
                                <i class="flaticon2-download"></i>
                              </a>
                              <a @click="focusFileInput(`supp_doc_${index}`)" href="javascript:;">{{
                                doc.file ? 'Reupload' : 'Upload'
                              }}</a>
                              <input
                                type="file"
                                style="display: none;"
                                :ref="`supp_doc_${index}`"
                                accept="application/pdf"
                                @change="handleFileUploaded($event, 'supplement', doc, index)"
                              />
                            </td>
                          </tr>
                          <tr v-if="doc.loading" :key="index + 'loader'">
                            <td class="p-0" colspan="2">
                              <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </b-col>
      </div>
    </template>
    <template v-slot:footer>
      <b-btn
        v-cs-loading="loading"
        variant="primary"
        class="font-weight-bolder font-size-h6 px-8 py-4"
        @click="onFinish()"
      >
        Finish
      </b-btn>
    </template>
  </KTWizardStepContent>
</template>
<script>
import ApiService from '@/helpers/api.service';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: '',
  props: {
    applicationData: {
      type: Object,
      default: () => {},
    },
    cooperative: {
      type: Object,
      default: () => {},
    },
    appId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      supplementals: 'supplemental/items',
      applicantSupplementals: 'applicantSupplemental/items',
    }),
  },
  data: () => ({
    loading: false,
    stepStatus: 0,
    applicantList: [],
    documentsData: {
      contract_of_sale: {
        file: '',
        name: 'Contract of Sale',
        loading: false,
        required: true,
      },
      // loan_commitment_letter: {
      //   file: '',
      //   name: 'Loan Commitment Letter',
      //   loading: false,
      // },
      // loan_application: {
      //   file: '',
      //   name: 'Loan Application',
      //   loading: false,
      // },
    },
    applicantsDocs: {
      landlord_reference_letter: {
        file: '',
        name: 'Landlord Reference Letter',
        required: true,
      },
      personal_reference_letters_first: {
        file: '',
        name: 'Personal Reference Letter(First)',
        required: true,
      },
      personal_reference_letters_second: {
        file: '',
        name: 'Personal Reference Letter(Second)',
        required: true,
      },

      account_statements_first: {
        file: '',
        name: 'Account Statements(First)',
        required: true,
      },
      account_statements_second: {
        file: '',
        name: 'Account Statements(Second)',
        required: true,
      },
      professional_reference_letter_first: {
        file: '',
        name: 'Professional Reference Letter(First)',
        required: true,
      },
      professional_reference_letter_second: {
        file: '',
        name: 'Professional Reference Letter(Second)',
        required: true,
      },
      loan_commitment_letter: {
        file: '',
        name: 'Loan Commitment',
        required: false,
      },
      loan_application: {
        file: '',
        name: 'Loan Application',
        required: false,
      },

      // employment_verification_letter: {
      //   file: '',
      //   name: 'Employment Verification Letter',
      //   required: true,
      // },
      pay_stubs_first: {
        file: '',
        name: 'Pay Stubs(First)',
        required: true,
      },
      pay_stubs_second: {
        file: '',
        name: 'Pay Stubs(Second)',
        required: true,
      },
      tax_returns_first: {
        file: '',
        name: 'Tax Returns(First)',
        required: true,
      },
      tax_returns_second: {
        file: '',
        name: 'Tax Returns(Second)',
        required: true,
      },
      w2_first: {
        file: '',
        name: 'W-2 or Equivalent(First)',
        required: true,
      },
      w2_second: {
        file: '',
        name: 'W-2 or Equivalent(Second)',
        required: true,
      },
    },
    supplementalDocs: [],
  }),
  methods: {
    ...mapActions({
      getSupplemental: 'supplemental/index',
      getApplicantSupplementals: 'applicantSupplemental/index',
      createApplicantSupplementals: 'applicantSupplemental/create',
      updateApplicantSupplementals: 'applicantSupplemental/update',
    }),
    focusFileInput(ref) {
      this.$refs[ref][0].click();
    },
    async handleFileUploaded(e, type, item, key) {
      const file = e.target.files.item(0);
      if (file) {
        if (file.type != 'application/pdf') return alert('Please upload only pdf files');
        try {
          if (type == 'general') {
            item.loading = true;
            const formData = new FormData();
            formData.append(`application[${key}]`, file);
            await ApiService.put(`applications/${this.appId}`, formData);
            item.file = URL.createObjectURL(file);
            item.loading = false;
          } else if (type == 'applicant') {
            item[`${key}_loading`] = true;
            const formData = new FormData();
            formData.append(`admin_user[${key}]`, file);
            await ApiService.put(`applicants/${item.id}`, formData);
            item[key] = URL.createObjectURL(file);
            item[`${key}_loading`] = false;
          } else if (type == 'supplement') {
            item.loading = true;
            const params = {
              supplemental: {
                id: item?.id || null,
                name: item.name,
                file: file,
                supplemental_form_id: item.supplemental_form_id,
                application_id: item.application_id,
                admin_user_id: item.admin_user_id,
              },
            };
            const action = item?.id ? this.updateApplicantSupplementals : this.createApplicantSupplementals;
            const response = await action(this.jsonToFormData(params)).finally(() => (item.loading = false));

            if (!item.id) item.id = response.id;
            item.file = URL.createObjectURL(file);
          }
          this.$store.dispatch('notifications/notfiy', { title: 'Successfully uploaded', type: 'success' });
        } catch (error) {
          Swal.fire({
            width: 600,
            height: 600,
            html: `Sorry, the PDF you are trying to upload is not meeting our requirements as
                   <b>Content Creator</b>,<b>Encoding software</b>, and/or <b>Where from</b> properties are missing from the PDF.
                   <br>Please try to re-scan, re-save, or re-export the PDF to your PC and then upload the newly exported PDF to the system again. 
                   <br>Thank you.`,
            icon: 'error',
            confirmButtonText: 'Ok, got it!',
          });
          console.error(error);
          e.target.value = null;
          item.loading = false;
          item[`${key}_loading`] = false;
        }
      }
    },

    onFinish() {
      this.loading = true;

      const invalidFields = [];
      // General Docs
      for (const k in this.documentsData) {
        if (this.documentsData[k].required && !this.documentsData[k].file) {
          invalidFields.push(`<strong>${this.documentsData[k].name} is missing</strong>`);
        }
      }

      // Applicant Docs
      for (const [index, el] of this.applicationData.applicants.data.entries()) {
        for (const k in this.applicantsDocs) {
          if (this.applicantsDocs[k].required && !el.attributes[k]) {
            invalidFields.push(`<strong>${this.applicantsDocs[k].name} is missing in Applicant ${index + 1}</strong>`);
          }
        }
      }

      // Supplemental Docs

      for (const supp of this.supplementalDocs) {
        if (!supp.file) {
          invalidFields.push(`<strong>Supplementary ${supp.name + supp.prefix} is missing</strong>`);
        }
      }

      if (invalidFields.length) {
        Swal.fire({
          width: 600,
          height: 600,
          html: `${invalidFields.join('<br>')}`,
          icon: 'error',
          confirmButtonText: 'Ok, got it!',
        });
        this.loading = false;
      } else {
        this.$emit('onFinish', { data: this.documentsData, done: () => (this.loading = false) });
      }
    },
    onCloseAndSave() {
      this.$emit('onSaveAndClose', false);
    },
  },
  async mounted() {
    Object.keys(this.documentsData).map((el) => {
      this.documentsData[el].file = this.applicationData[el];
      return el;
    });

    this.applicantList = this.applicationData.applicants.data.map((el) => {
      const data = el.attributes;
      data.id = el.id;
      for (const k in this.applicantsDocs) {
        this.$set(data, `${k}_loading`, false);
      }
      return data;
    });

    await this.$await(
      this.getSupplemental({
        where: {
          cooperative_id: this.cooperative.id,
        },
      })
    );

    await this.$await(this.getApplicantSupplementals());

    for (const supplemental of this.supplementals) {
      for (const [index, applicant] of this.applicationData.applicants.data.entries()) {
        const doc = await this.applicantSupplementals.find((s) => {
          return s?.supplemental_form?.data?.id == supplemental.id && s.admin_user?.data?.id == applicant.id;
        });
        this.supplementalDocs.push({
          id: doc?.id || null,
          name: supplemental.name,
          supplemental_form_id: supplemental.id,
          prefix: ' - Applicant ' + Number(index + 1),
          file: doc?.file || '',
          application_id: this.appId,
          admin_user_id: applicant.id,
          loading: false,
        });
      }
    }
  },
};
</script>
