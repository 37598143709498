<template>
  <div>
    <h2 class="h1 text-dark text-center mb-12">{{ getCompanyName }}</h2>
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Enter first name
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="subject.$model.first_name"
            :state="validateState(subject.first_name)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Enter last name
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="subject.$model.last_name"
            :state="validateState(subject.last_name)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <h2 class="h1 text-dark text-center mb-12">Contact</h2>
    <b-form-group>
      <template v-slot:label>
        Email Address
        <span class="text-danger">*</span>
      </template>
      <b-form-input
        type="text"
        class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        v-model="subject.$model.email"
        :state="validateState(subject.email)"
      />
    </b-form-group>
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Home Phone Number
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="subject.$model.home_phone"
            :state="validateState(subject.home_phone)"
            v-mask="defaultMask.phone"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Cell Phone Number
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="subject.$model.phone"
            :state="validateState(subject.phone)"
            v-mask="defaultMask.phone"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <h2 class="h1 text-dark text-center mb-12">Address</h2>
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Street address
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="subject.$model.street_address"
            :state="validateState(subject.street_address)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Apt/Suite
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="subject.$model.suite"
            :state="validateState(subject.suite)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4">
        <b-form-group>
          <template v-slot:label>
            City
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="subject.$model.city"
            @keypress="onlyLetter($event)"
            :state="validateState(subject.city)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <template v-slot:label>
            State
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="subject.$model.state"
            :state="validateState(subject.state)"
            :options="stateOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
          />
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <template v-slot:label>
            Zip
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="subject.$model.zip"
            :state="validateState(subject.zip)"
            v-mask="defaultMask.zip"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'SubjectInfoForm',
  props: {
    subject: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    getCompanyName() {
      return this.subject && this.subject.$model ? this.subject.$model.contact_type : '';
    },
  },
};
</script>
