<template>
  <div>
    <h3 class="text-dark mb-4">Outstanding Judgments</h3>
    <div v-if="!v.applicantForm5.$model.outstanding_judgments.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm5.$model.outstanding_judgments"
      :key="`outstanding_judgments${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Debtor
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            Explanation of Judgment
            <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.explanation"
            :state="validateState(v.applicantForm5.outstanding_judgments.$each[index].explanation)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'outstanding_judgments', item.id, 'others')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('outstanding_judgments', 'others')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Personal Bankruptcy within last 7 years</h3>
    <div v-if="!v.applicantForm5.$model.personal_bankruptcies.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm5.$model.personal_bankruptcies"
      :key="`personal_bankruptcies${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Debtor
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Result
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.result"
            :state="validateState(v.applicantForm5.personal_bankruptcies.$each[index].result)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            Explanation
            <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.explanation"
            :state="validateState(v.applicantForm5.personal_bankruptcies.$each[index].explanation)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'personal_bankruptcies', item.id, 'others')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('personal_bankruptcies', 'others')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Business Bankruptcy within last 7 years</h3>
    <div v-if="!v.applicantForm5.$model.business_bankruptcies.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm5.$model.business_bankruptcies"
      :key="`business_bankruptcies${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Debtor
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Result
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.result"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            Explanation
            <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.explanation"
            :state="validateState(v.applicantForm5.business_bankruptcies.$each[index].explanation)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'business_bankruptcies', item.id, 'others')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('business_bankruptcies', 'others')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Owned property foreclosed on</h3>
    <div v-if="!v.applicantForm5.$model.property_forcloseds.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm5.$model.property_forcloseds"
      :key="`property_forcloseds${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Debtor
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            Address of property
            <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.address"
            :state="validateState(v.applicantForm5.property_forcloseds.$each[index].address)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group>
          <template v-slot:label>
            Explanation
            <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.explanation"
            :state="validateState(v.applicantForm5.property_forcloseds.$each[index].explanation)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'property_forcloseds', item.id, 'others')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('property_forcloseds', 'others')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>
  </div>
</template>

<script>
import ApiService from '@/helpers/api.service';

export default {
  name: 'OtherInfoForm',
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    applicationId: {
      type: [String, Number],
      required: true,
    },
    applicants: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      defaultassets: {
        outstanding_judgments: {
          id: '',
          admin_user_id: '',
          explanation: '',
        },
        personal_bankruptcies: {
          id: '',
          admin_user_id: '',
          result: '',
          explanation: '',
        },
        business_bankruptcies: {
          id: '',
          admin_user_id: '',
          result: '',
          explanation: '',
        },
        property_forcloseds: {
          id: '',
          admin_user_id: '',
          address: '',
          explanation: '',
        },
      },
    };
  },
  computed: {
    applicantListOptions() {
      if (this.applicants) {
        const data = this.applicants.map((applicant, index) => ({
          text: `Applicant ${index + 1}`,
          value: applicant.id,
        }));
        data.push({
          text: `Joint`,
          value: null,
        });
        return data;
      }
      return [];
    },
  },
  methods: {
    filterApplicationsList(data, key, value, type = 'any') {
      if (type == 'any') return this.applicantListOptions;
      if (type == 'unique') {
        return this.applicantListOptions.filter((a) => {
          const exists = data.find((d) => d[key] == a.value);
          return !exists || a.value == value;
        });
      }
      if (type == 'unique_without_joint') {
        return this.applicantListOptions
          .filter((a) => a.value)
          .filter((a) => {
            const exists = data.find((d) => d[key] == a.value);
            return !exists || a.value == value;
          });
      }
      if (type == 'without_joint') {
        return this.applicantListOptions.filter((a) => a.value);
      }
    },
    addItem(group) {
      const defItem = Object.assign({}, this.defaultassets[group]);
      defItem.admin_user_id = -1;
      defItem.key = this.generateUid();
      this.v.applicantForm5.$model[group].push(defItem);
    },
    deleteItem(index, group, id) {
      this.v.applicantForm5.$model[group].splice(index, 1);

      if (id) {
        ApiService.delete(`${group}/${id}`);
      }
    },
  },
  created() {
    const params = {
      elasticsearch: true,
      per: Math.pow(10, 12),
      where: {
        application_id: this.applicationId,
      },
    };

    Object.keys(this.v.applicantForm5.$model).forEach((key) => {
      ApiService.query(key, { params }).then((res) => {
        this.v.applicantForm5.$model[key] = res.data.data.map((elRes) => {
          const resD = Object.assign({}, this.defaultassets[key]);
          Object.keys(resD).map((k) => {
            resD[k] = elRes.attributes[k];
            return k;
          });
          resD.id = elRes.id;
          resD.admin_user_id = elRes.attributes.admin_user.data ? elRes.attributes.admin_user.data.id : null;
          resD.key = this.generateUid();
          return resD;
        });
      });
    });
  },
};
</script>
