<template>
  <KTWizardStepContent :step-number="4" title="Transactional Information">
    <template v-slot:save-btn>
      <b-btn
        v-cs-loading="saveAndExitSubmiting"
        variant="light-primary"
        size="lg"
        :disabled="footerSubmiting"
        @click="saveAndExit"
      >
        Save and Exit
      </b-btn>
    </template>
    <template v-slot:nav>
      <KTWizardStepContentNavList>
        <template v-for="(kstep, kindex) in steps">
          <KTWizardStepContentNavItem
            :key="kindex"
            :order-number="kindex + 1"
            :title="kstep.title"
            :is-checked="step > kindex"
            :is-current="step == kindex"
          />
        </template>
      </KTWizardStepContentNavList>
    </template>
    <template v-slot:body>
      <appartment-form v-if="step === 0" :v="$v" />
      <intended-appartment-form v-if="step === 1" :v="$v" />
      <purchase-form v-if="step === 2" :v="$v" />

      <div v-for="(contact, index) in $v.contactsForm.$model" :key="index">
        <subject-info-form v-if="step === index + 3" :subject="$v.contactsForm.$each[index]" />
      </div>
    </template>
    <template v-slot:footer>
      <b-btn
        v-if="step > 0"
        :disabled="saveAndExitSubmiting"
        variant="light-primary"
        class="font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3"
        @click="back"
      >
        <span class="svg-icon svg-icon-md mr-2">
          <inline-svg src="/media/svg/icons/Navigation/Left-2.svg" />
        </span>
        Back
      </b-btn>

      <b-btn
        v-cs-loading="footerSubmiting"
        variant="primary"
        class="font-weight-bolder font-size-h6 py-4 ml-auto"
        :disabled="saveAndExitSubmiting"
        :class="{ 'pl-8 pr-4': !isLastStep, 'px-8': isLastStep }"
        @click="next"
      >
        {{ isLastStep ? 'Finish' : 'Next' }}
        <span v-if="!isLastStep" class="svg-icon svg-icon-md ml-2">
          <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
        </span>
      </b-btn>
    </template>
  </KTWizardStepContent>
</template>

<script>
import { required, email, numeric, integer, maxLength, minLength, minValue, maxValue } from 'vuelidate/lib/validators';

import ApiService from '@/helpers/api.service';
import AppartmentForm from './AppartmentForm';
import IntendedAppartmentForm from './IntendedAppartmentForm';
import { mapGetters } from 'vuex';
import PurchaseForm from './PurchaseForm';
import SubjectInfoForm from './SubjectInfoForm';
import _ from 'lodash';

export default {
  name: 'Main',
  components: {
    SubjectInfoForm,
    PurchaseForm,
    IntendedAppartmentForm,
    AppartmentForm,
  },
  props: {
    cooperative: {
      type: Object,
      default: () => ({}),
    },
    applicant: {
      type: Object,
      default: () => {},
    },
    appId: {
      type: [String, Number],
      required: true,
    },
  },
  validations() {
    return {
      applicantForm: {
        appartment_number: { required },
        number_of_shares_of_stock: { required },
        street_address: { required },
        suite: {},
        city: { required },
        state: {},
        zip: { required, minLength: minLength(5) },
      },
      applicantForm2: {
        instruments: {
          $each: {
            name: { required },
          },
        },
        alterations: {
          $each: {
            description: { required },
          },
        },
        subtenants: {
          $each: {
            name: { required },
          },
        },
        pets: {
          $each: {
            number: { required },
            type_of_pets: { required },
            breed: { required },
            type_of_future_pets: { required },
          },
        },
        occupants: {
          $each: {
            name: { required },
            relationship: { required },
            criminal_conviction: { required },
            type_of_criminal_conviction: {
              isValid: function(value, circuit) {
                return !circuit.criminal_conviction ? true : value.length;
              },
            },
            dimplomatic_status: { required },
            defendant_in_lawsuit: { required },
            explanation: {
              isValid: function(value, circuit) {
                return !circuit.defendant_in_lawsuit ? true : value.length;
              },
            },
          },
        },
      },
      applicantForm3: {
        purchase_price: { required },
        down_payment: { required },
        name_of_source: { required },
        monthly_maintenance: { required },
        require: {},
        purchase_price_loan_amount: {
          required: this.applicantForm3.require ? required : false,
        },
        purchase_price_interest_rate: {
          required: this.applicantForm3.require ? required : false,
        },
        purchase_price_term: {
          required: this.applicantForm3.require ? required : false,
          integer,
        },
        purchase_price_monthly_payment: {
          required: this.applicantForm3.require ? required : false,
          integer,
          numeric,
        },
      },
      contactsForm: {
        $each: {
          first_name: { required },
          last_name: { required },
          email: { required, email },
          home_phone: { required, minLength: minLength(13) },
          phone: { required, minLength: minLength(13) },
          street_address: { required },
          suite: {},
          city: { required },
          state: { required },
          zip: { required, minLength: minLength(5) },
        },
      },
    };
  },
  data() {
    return {
      saveAndExitSubmiting: false,
      footerSubmiting: false,
      step: 0,
      steps: [
        {
          title: 'Apartment',
          description: 'Applicants',
        },
        {
          title: 'Intended Use for Apartment',
          description: 'Personal Information for applicant',
        },
        {
          title: 'Purchase Price',
          description: 'Educational Information for applicant',
        },
        {
          title: 'Seller',
          description: 'Residential Information for applicant',
        },
        {
          title: 'Seller Attorney Contact Information',
          description: 'Employment Information for applicant',
        },
        {
          title: 'Purchase Attorney Contact Information',
          description: 'Miscellaneous Information for applicant',
        },
        {
          title: "Seller's Broker Information",
          description: 'Miscellaneous Information for applicant',
        },
        {
          title: "Applicant's Broker Information",
          description: 'Miscellaneous Information for applicant',
        },
      ],
      applicantForm: {},
      applicantForm2: {},
      applicantForm3: {
        require: false,
      },
      contactsForm: [],
    };
  },
  watch: {
    step: {
      handler() {
        $('.wizard-custom-content-body').scrollTop(0);
      },
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    isLastStep() {
      return this.step + 1 == this.steps.length;
    },
  },
  methods: {
    initForms(form) {
      this.applicantForm = {
        appartment_number: form.appartment_number || '',
        number_of_shares_of_stock: form.number_of_shares_of_stock || '',
        street_address: form.street_address || '',
        suite: form.suite || '',
        city: form.city || '',
        state: form.state || '',
        zip: form.zip || '',
      };

      this.applicantForm2 = {
        instruments: form.instruments.data.length
          ? form.instruments.data.map((record) => {
              return {
                ...record,
                ...record.attributes,
              };
            })
          : [],
        alterations: form.alterations.data.length
          ? form.alterations.data.map((record) => {
              return {
                ...record,
                ...record.attributes,
              };
            })
          : [],
        subtenants: form.subtenants.data.length
          ? form.subtenants.data.map((record) => {
              return {
                ...record,
                ...record.attributes,
              };
            })
          : [],
        pets: form.pets.data.length
          ? form.pets.data.map((record) => {
              return {
                ...record,
                ...record.attributes,
              };
            })
          : [],
        occupants: form.occupants.data
          ? form.occupants.data.map((record) => {
              return {
                ...record,
                ...record.attributes,
              };
            })
          : [],
      };

      this.applicantForm3 = {
        purchase_price: form.purchase_price || '',
        down_payment: form.down_payment || '',
        name_of_source: form.name_of_source || '',
        monthly_maintenance: form.monthly_maintenance || '',
        require: form.require == true || false,
        purchase_price_loan_amount: form.purchase_price_loan_amount || '',
        purchase_price_interest_rate: form.purchase_price_interest_rate || '',
        purchase_price_term: form.purchase_price_term || '',
        purchase_price_monthly_payment: form.purchase_price_monthly_payment || '',
      };

      this.getContacts();
    },
    getContacts() {
      const params = {
        elasticsearch: true,
        where: {
          application_id: this.appId,
        },
      };

      ApiService.query('contacts', { params }).then((res) => {
        const { data } = res.data;

        const contactsForm = data.map((contact) => {
          return {
            ...contact,
            ...contact.attributes,
          };
        });

        const types = [
          { name: 'Seller', order: 0 },
          { name: 'Seller Attorney', order: 1 },
          { name: 'Purchase Attorney', order: 2 },
          { name: 'Seller Broker', order: 3 },
          { name: 'Applicant Broker', order: 4 },
        ];

        types.forEach((type) => {
          const contact = _.find(contactsForm, { attributes: { contact_type: type.name } });
          if (contact) {
            this.contactsForm[type.order] = contact;
          } else {
            this.contactsForm[type.order] = {
              status: 'Draft',
              company_name: '',
              first_name: '',
              last_name: '',
              email: '',
              home_phone: '',
              phone: '',
              street_address: '',
              suite: '',
              city: '',
              state: '',
              zip: '',
              contact_type: type.name,
            };
          }
        });

        console.log(this.contactsForm);
      });
    },
    saveAndExit() {
      this.saveAndExitSubmiting = true;
      this.next();
      $('body').scrollTop();
    },
    updateForm(form) {
      const formData = new FormData();

      Object.keys(form).forEach((record) => {
        formData.append(`application[${record}]`, form[record]);
      });

      return ApiService.put(`applications/${this.appId}`, formData);
    },
    updateContactsForm(form) {
      if (form.id) {
        return ApiService.put(`contacts/${form.id}`, { application_id: this.appId, ...form });
      } else {
        return ApiService.post(`contacts`, {
          application_id: this.appId,
          cooperative_id: this.cooperative.id,
          ...form,
        }).then((res) => {
          this.$set(form, 'id', res.data.data.id);
          return res;
        });
      }
    },
    updateMultipleForms(form) {
      const promises = [];

      Object.keys(form).forEach((key) => {
        form[key].forEach((record) => {
          record = {
            ...record,
            admin_user_id: this.user.id,
            application_id: this.appId,
          };

          let promise;
          if (record.id) {
            promise = ApiService.put(`${key}/${record.id}`, record);
          } else {
            promise = ApiService.post(`${key}`, record);
          }

          promises.push(promise);
        });
      });

      return Promise.all(promises);
    },
    async next() {
      if (this.step == 0) {
        if (!this.$v.applicantForm.$invalid) {
          this.footerSubmiting = true;
          this.updateForm(this.$v.applicantForm.$model)
            .then(() => {
              this.footerSubmiting = false;
              if (this.saveAndExitSubmiting) {
                this.$emit('close');
              }
              return (this.step += 1);
            })
            .finally(() => {
              this.footerSubmiting = false;
            });
        } else {
          this.alertValidationFields(this.$v.applicantForm, []);
          this.$v.applicantForm.$touch();
          this.saveAndExitSubmiting = false;
        }
      }

      if (this.step == 1) {
        if (!this.$v.applicantForm2.$invalid) {
          this.footerSubmiting = true;
          this.updateMultipleForms(this.$v.applicantForm2.$model)
            .then(() => {
              this.footerSubmiting = false;
              if (this.saveAndExitSubmiting) {
                this.$emit('close');
              }
              return (this.step += 1);
            })
            .finally(() => {
              this.footerSubmiting = false;
            });
        } else {
          this.alertValidationFields(this.$v.applicantForm2, []);
          this.$v.applicantForm2.$touch();
          this.saveAndExitSubmiting = false;
        }
      }

      if (this.step == 2) {
        if (!this.$v.applicantForm3.$invalid) {
          this.footerSubmiting = true;
          this.updateForm(this.$v.applicantForm3.$model)
            .then(() => {
              this.footerSubmiting = false;
              if (this.saveAndExitSubmiting) {
                this.$emit('close');
              }
              return (this.step += 1);
            })
            .finally(() => {
              this.footerSubmiting = false;
            });
        } else {
          this.alertValidationFields(this.$v.applicantForm3, []);
          this.$v.applicantForm3.$touch();
          this.saveAndExitSubmiting = false;
        }
      }

      if (this.step == 3 || this.step == 4 || this.step == 5 || this.step == 6 || this.isLastStep) {
        if (!this.$v.contactsForm.$each[this.step - 3].$invalid) {
          this.footerSubmiting = true;
          this.updateContactsForm(this.$v.contactsForm.$model[this.step - 3])
            .then(() => {
              this.footerSubmiting = false;

              if (this.isLastStep) {
                return this.$emit('finish');
              }

              if (this.saveAndExitSubmiting) {
                this.$emit('close');
              }
              return (this.step += 1);
            })
            .finally(() => {
              this.footerSubmiting = false;
            });
        } else {
          this.alertValidationFields(this.$v.contactsForm.$each[this.step - 3], []);
          this.$v.contactsForm.$each[this.step - 3].$touch();
          this.saveAndExitSubmiting = false;
        }
      }
    },
    back() {
      this.step -= 1;
    },
  },
  created() {
    this.initForms(this.applicant);
  },
};
</script>
