<template>
  <div>
    <div v-for="(applicant, index) in v.applicantForm2.$model" :key="index">
      <h2 class="h1 text-dark text-center mb-12">Personal inforamtion for applicant {{ index + 1 }}</h2>
      <h3 class="text-dark text-center my-12">Address</h3>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Street address
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              required
              v-model="applicant.street_address"
              :state="validateState(v.applicantForm2.$each[index].street_address)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Apt/Suite
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.suite"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              City
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              required
              v-model="applicant.city"
              @keypress="onlyLetter($event)"
              :state="validateState(v.applicantForm2.$each[index].city)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              State
              <span class="text-danger">*</span>
            </template>
            <b-form-select
              :options="stateOptions"
              class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              required
              v-model="applicant.state"
              :state="validateState(v.applicantForm2.$each[index].state)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <template v-slot:label>
              Zip
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-mask="defaultMask.zip"
              v-model="applicant.zip"
              :state="validateState(v.applicantForm2.$each[index].zip)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="text-dark text-center my-12">Personal</h3>

      <b-row>
        <b-col lg="6">
          <b-form-group :state="validateState(v.applicantForm2.$each[index].birth_date)">
            <template v-slot:label>
              Date of birth
              <span class="text-danger">*</span>
            </template>
            <date-picker :id="`${index}_applicant.birth_date`" v-model="applicant.birth_date"></date-picker>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Social Security Number
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.social_security_number"
              :state="validateState(v.applicantForm2.$each[index].social_security_number)"
              v-mask="defaultMask.social_security_number"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Driver's License State
              <span class="text-danger">*</span>
            </template>
            <b-form-select
              :options="stateOptions"
              class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              required
              v-model="applicant.drivers_license_state"
              :state="validateState(v.applicantForm2.$each[index].drivers_license_state)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Driver's license Number
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.drivers_license_number"
              :state="validateState(v.applicantForm2.$each[index].drivers_license_number)"
              v-mask="'###-###-###'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="text-dark text-center my-12">Contact Information</h3>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Home Phone Number
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.home_phone_number"
              :state="validateState(v.applicantForm2.$each[index].home_phone_number)"
              v-mask="'(###)###-####'"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Cell Phone Number
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="text"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="applicant.phone"
              :state="validateState(v.applicantForm2.$each[index].phone)"
              v-mask="'(###)###-####'"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalInfoForm',
  props: {
    v: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
