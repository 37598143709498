<template>
  <div class="position-relative">
    <span class="position-absolute dollar-sign">$</span>
    <v-money ref="input" class="form-control" :value="valueMutated" :class="[classes, valid]"></v-money>
  </div>
</template>

<script>
export default {
  name: 'CentToDollarInput',
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    classes: {
      type: String,
    },
    state: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      converted: 0,
    };
  },
  computed: {
    valid() {
      if (this.state === null) return '';
      return this.state === true ? 'is-valid' : 'is-invalid';
    },
    bind() {
      const bind = {};

      if (this.state !== null) {
        bind.state = this.state;
      }

      return bind;
    },
    valueMutated: {
      get() {
        return this.converted;
      },
      set(e) {
        const val = parseFloat(e.target.value.replace(',', ''), 10);

        if (!val) {
          if (val === 0) {
            return this.$emit('input', 0);
          }

          return this.$emit('input', '');
        }
        this.converted = e.target.value;
        this.$emit('input', parseFloat(val * 100));
      },
    },
  },
  created() {
    this.converted = parseFloat(_.clone(this.value) / 100);
  },
  mounted() {
    this.$refs.input.$el.addEventListener('keyup', this.onKeyUp);
  },
  methods: {
    onKeyUp(e) {
      this.valueMutated = e;
    },
  },
};
</script>

<style lang="scss">
.dollar-sign {
  top: 50%;
  left: 27px;
  transform: translateY(-50%);
  font-family: 1rem;

  & + input {
    padding-left: 45px !important;
  }
}
</style>
