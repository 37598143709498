<template>
  <div>
    <h2 class="h1 text-dark text-center mb-12">Purchase Price</h2>
    <b-form-group>
      <template v-slot:label>
        Purchase price of the cooperative
        <span class="text-danger">*</span>
      </template>
      <cent-to-dollar-input
        v-model="v.applicantForm3.$model.purchase_price"
        classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        :state="validateState(v.applicantForm3.purchase_price)"
      />
    </b-form-group>
    <b-form-group>
      <template v-slot:label>
        Down payment
        <span class="text-danger">*</span>
      </template>
      <cent-to-dollar-input
        v-model="v.applicantForm3.$model.down_payment"
        classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        :state="validateState(v.applicantForm3.down_payment)"
      />
    </b-form-group>
    <b-form-group>
      <template v-slot:label>
        Name of source of down payment
        <span class="text-danger">*</span>
      </template>
      <b-form-input
        type="text"
        class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        required
        v-model="v.applicantForm3.$model.name_of_source"
        :state="validateState(v.applicantForm3.name_of_source)"
      />
    </b-form-group>
    <b-form-group>
      <template v-slot:label>
        Monthly maintenance
        <span class="text-danger">*</span>
      </template>
      <cent-to-dollar-input
        v-model="v.applicantForm3.$model.monthly_maintenance"
        classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
        :state="validateState(v.applicantForm3.monthly_maintenance)"
      />
    </b-form-group>

    <b-form-group>
      <template v-slot:label>
        Will you require a loan to purchase the cooperative ?
      </template>
      <div class="radio-inline">
        <label class="radio">
          <input type="radio" name="require" v-model="v.applicantForm3.$model.require" :value="true" />
          <span />
          Yes
        </label>
        <label class="radio">
          <input type="radio" name="require1" v-model="v.applicantForm3.$model.require" :value="false" />
          <span />
          No
        </label>
      </div>
    </b-form-group>
    <div v-if="v.applicantForm3.$model.require == true">
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Amount of loan
              <span class="text-danger">*</span>
            </template>
            <cent-to-dollar-input
              v-model="v.applicantForm3.$model.purchase_price_loan_amount"
              classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              :state="validateState(v.applicantForm3.purchase_price_loan_amount)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Interest Rate
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="number"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="v.applicantForm3.$model.purchase_price_interest_rate"
              :state="validateState(v.applicantForm3.purchase_price_interest_rate)"
              :min="0"
              :max="100"
              :formatter="percentageFormatter"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Term in year(s)
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              type="number"
              class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              v-model="v.applicantForm3.$model.purchase_price_term"
              :state="validateState(v.applicantForm3.purchase_price_term)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <template v-slot:label>
              Monthly loan payment
              <span class="text-danger">*</span>
            </template>
            <cent-to-dollar-input
              v-model="v.applicantForm3.$model.purchase_price_monthly_payment"
              classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
              :state="validateState(v.applicantForm3.purchase_price_monthly_payment)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CentToDollarInput from '../../../form/CentToDollarInput';
export default {
  name: 'PurchaseForm',
  components: { CentToDollarInput },
  props: {
    v: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
