<template>
  <div>
    <h2 class="h1 text-dark text-center mb-12">Apartment</h2>
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Apartment
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.applicantForm.$model.appartment_number"
            :state="validateState(v.applicantForm.appartment_number)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Number of Shares of Stock
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="v.applicantForm.$model.number_of_shares_of_stock"
            :state="validateState(v.applicantForm.number_of_shares_of_stock)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <h2 class="h1 text-dark text-center mb-12">Address</h2>
    <b-row>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Street address
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            required
            v-model="v.applicantForm.$model.street_address"
            :state="validateState(v.applicantForm.street_address)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Apt/Suite
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="v.applicantForm.$model.suite"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4">
        <b-form-group>
          <template v-slot:label>
            City
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="v.applicantForm.$model.city"
            @keypress="onlyLetter($event)"
            :state="validateState(v.applicantForm.city)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <template v-slot:label>
            State
          </template>
          <b-form-select
            v-model="v.applicantForm.$model.state"
            :options="stateOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <template v-slot:label>
            Zip
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="v.applicantForm.$model.zip"
            :state="validateState(v.applicantForm.zip)"
            v-mask="defaultMask.zip"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AppartmentForm',
  props: {
    v: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
