<template>
  <KTWizardStepContent :step-number="2" title="Personal Information">
    <template v-slot:save-btn>
      <b-btn
        v-cs-loading="saveAndExitSubmiting"
        variant="light-primary"
        size="lg"
        :disabled="footerSubmiting"
        @click="saveAndExit"
      >
        Save and Exit
      </b-btn>
    </template>
    <template v-slot:nav>
      <KTWizardStepContentNavList>
        <template v-for="(kstep, kindex) in steps">
          <KTWizardStepContentNavItem
            :key="kindex"
            :order-number="kindex + 1"
            :title="kstep.title"
            :is-checked="step > kindex"
            :is-current="step == kindex"
          />
        </template>
      </KTWizardStepContentNavList>
    </template>
    <template v-slot:body>
      <applicants-form v-if="step === 0" @remove="removeApplicant" :v="$v" />
      <personal-info-form v-if="step === 1" :v="$v" />
      <educational-form v-if="step === 2" :applicants="applicant.applicants.data" :v="$v" />
      <residental-form v-if="step === 3" :applicants="applicant.applicants.data" :v="$v" />
      <employment-form v-if="step === 4" :applicants="applicant.applicants.data" :v="$v" />
      <misc-form v-if="step === 5" :applicants="applicant.applicants.data" :v="$v" />
    </template>
    <template v-slot:footer>
      <b-btn
        v-if="step > 0"
        :disabled="saveAndExitSubmiting"
        variant="light-primary"
        class="font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3"
        @click="back"
      >
        <span class="svg-icon svg-icon-md mr-2">
          <inline-svg src="/media/svg/icons/Navigation/Left-2.svg" />
        </span>
        Back
      </b-btn>

      <b-btn
        v-cs-loading="footerSubmiting"
        variant="primary"
        class="font-weight-bolder font-size-h6 py-4 ml-auto"
        :disabled="saveAndExitSubmiting"
        :class="{ 'pl-8 pr-4': !isLastStep, 'px-8': isLastStep }"
        @click="next()"
      >
        {{ isLastStep ? 'Finish' : 'Next' }}
        <span v-if="!isLastStep" class="svg-icon svg-icon-md ml-2">
          <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
        </span>
      </b-btn>
    </template>
  </KTWizardStepContent>
</template>

<script>
import { required, email, integer, maxLength, minLength } from 'vuelidate/lib/validators';
import ApplicantsForm from './ApplicantsForm';
import PersonalInfoForm from './PersonalInfoForm';
import EducationalForm from './EducationalForm';
import ResidentalForm from './ResidentalForm';
import EmploymentForm from './EmploymentForm';
import MiscForm from './MiscForm';
import ApiService from '@/helpers/api.service';
import { mapGetters } from 'vuex';

export default {
  name: 'Main',
  components: { MiscForm, EmploymentForm, ResidentalForm, EducationalForm, PersonalInfoForm, ApplicantsForm },
  props: {
    cooperative: {
      type: Object,
      required: true,
    },
    appId: {
      type: [String, Number],
      required: true,
    },
    applicant: {
      type: Object,
      default: () => {},
    },
  },
  validations() {
    return {
      applicantForm: {
        $each: {
          first_name: { required },
          last_name: { required },
          email: { required, email },
        },
      },
      applicantForm2: {
        $each: {
          street_address: { required },
          city: { required },
          suite: {},
          state: { required },
          zip: { required, minLength: minLength(5) },
          birth_date: { required },
          social_security_number: { required, minLength: minLength(11) },
          drivers_license_state: { required },
          drivers_license_number: { required, minLength: minLength(11) },
          home_phone_number: { required, minLength: minLength(13) },
          phone: { required, minLength: minLength(13) },
        },
      },
      applicantForm3: {},
      applicantForm4: {
        $each: {
          landlords: {
            email: {
              email: function(value, vform) {
                return vform.have ? email(value) : true;
              },
            },
            phone: {
              minLength: function(value, vform) {
                return vform.have && value != '' ? value.length >= 13 : true;
              },
            },
          },
          prior_landlords: {
            email: {
              email: function(value, vform) {
                return vform.have ? email(value) : true;
              },
            },
            phone: {
              minLength: function(value, vform) {
                return vform.have && value != '' ? value.length >= 13 : true;
              },
            },
          },
        },
      },
      applicantForm5: {
        $each: {
          current_employments: {
            supervisor_email: {
              email: function(value, vform) {
                return vform.have ? email(value) : true;
              },
            },
            supervisor_phone: {
              minLength: function(value, vform) {
                return vform.have && value != '' ? value.length >= 13 : true;
              },
            },
          },
          previous_employments: {
            supervisor_email: {
              email: function(value, vform) {
                return vform.have ? email(value) : true;
              },
            },
            supervisor_phone: {
              minLength: function(value, vform) {
                return vform.have && value != '' ? value.length >= 13 : true;
              },
            },
          },
        },
      },
      applicantForm6: {},
    };
  },
  data() {
    return {
      saveAndExitSubmiting: false,
      footerSubmiting: false,
      step: 0,
      steps: [
        {
          title: 'Applicants',
          description: 'Applicants',
        },
        {
          title: 'Personal Info',
          description: 'Personal Information for applicant',
        },
        {
          title: 'Educational',
          description: 'Educational Information for applicant',
        },
        {
          title: 'Residential',
          description: 'Residential Information for applicant',
        },
        {
          title: 'Employment',
          description: 'Employment Information for applicant',
        },
        {
          title: 'Misc',
          description: 'Miscellaneous Information for applicant',
        },
      ],
      applicantForm: {},
      applicantForm2: {},
      applicantForm3: {},
      applicantForm4: {},
      applicantForm5: {},
      applicantForm6: {},
      labels: {
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    isLastStep() {
      return this.step + 1 == this.steps.length;
    },
  },
  watch: {
    step: {
      handler() {
        $('.wizard-custom-content-body').scrollTop(0);
      },
    },
  },
  methods: {
    removeApplicant(index) {
      this.applicant.applicants.data.splice(index, 1);
      this.initForms(this.applicant);
      // [Temp] Update application,for background fee calculation accordingly applicant count

      ApiService.put(`applications/${this.appId}`);
    },
    initForms(form) {
      this.applicantForm = form.applicants.data.map((applicant) => {
        return {
          id: applicant.id,
          first_name: applicant.attributes.first_name,
          last_name: applicant.attributes.last_name,
          email: applicant.attributes.email,
        };
      });

      this.applicantForm2 = form.applicants.data.map((applicant) => {
        return {
          street_address: applicant.attributes.street_address,
          city: applicant.attributes.city,
          suite: applicant.attributes.suite,
          state: applicant.attributes.state,
          zip: applicant.attributes.zip,
          birth_date: applicant.attributes.birth_date,
          social_security_number: applicant.attributes.social_security_number,
          drivers_license_state: applicant.attributes.drivers_license_state,
          drivers_license_number: applicant.attributes.drivers_license_number,
          home_phone_number: applicant.attributes.home_phone_number,
          phone: applicant.attributes.phone,
        };
      });

      this.applicantForm3 = form.applicants.data.map((applicant) => {
        return {
          high_schools: {
            attend: false,
            name: '',
            start_date_of_attendance: '',
            end_date_of_attendance: '',
            graduated: '',
            year_graduated: '',
            degree: '',
            concentration: '',
            honors: '',
            id: '',
          },
          colleges: {
            attend: false,
            name: '',
            start_date_of_attendance: '',
            end_date_of_attendance: '',
            graduated: '',
            year_graduated: '',
            degree: '',
            concentration: '',
            additional_concentration: '',
            honors: '',
            id: '',
          },
          graduate_schools: {
            attend: false,
            name: '',
            start_date_of_attendance: '',
            end_date_of_attendance: '',
            graduated: '',
            year_graduated: '',
            degree: '',
            concentration: '',
            honors: '',
            id: '',
          },
          licenses: {
            have: false,
            year: '',
            license_type: '',
            date: '',
            id: '',
          },
        };
      });

      this.applicantForm4 = form.applicants.data.map((applicant) => {
        return {
          landlords: {
            id: '',
            have: false,
            name: '',
            phone: '',
            email: '',
            tenancy_start_date: '',
            tenancy_end_date: '',
            address: '',
          },
          prior_landlords: {
            id: '',
            have: false,
            name: '',
            phone: '',
            email: '',
            tenancy_start_date: '',
            tenancy_end_date: '',
            address: '',
          },
        };
      });

      this.applicantForm5 = form.applicants.data.map((applicant) => {
        return {
          current_employments: {
            id: '',
            have: false,
            name: '',
            address: '',
            supervisor_name: '',
            supervisor_phone: '',
            supervisor_email: '',
            start_date: '',
            end_date: '',
            job_title: '',
            responsibilities: '',
          },
          previous_employments: {
            id: '',
            have: false,
            name: '',
            address: '',
            supervisor_name: '',
            supervisor_phone: '',
            supervisor_email: '',
            start_date: '',
            end_date: '',
            job_title: '',
            responsibilities: '',
            reason_for_leaving: '',
          },
        };
      });

      this.applicantForm6 = form.applicants.data.map((applicant) => {
        return {
          legal_resident: applicant.attributes.legal_resident == true,
          diplomat: applicant.attributes.diplomat == true,
          militaries: {
            id: '',
            have: false,
            branch_of_service: '',
            start_year_of_service: '',
            end_year_of_service: '',
            type_of_discharge: '',
          },
          crimes: {
            id: '',
            have: false,
            type_of_conviction: '',
            type_of_convicted_of: '',
          },
          evicteds: {
            id: '',
            have: false,
            date: '',
            explanation: '',
          },
        };
      });
    },
    saveAndExit() {
      this.saveAndExitSubmiting = true;
      this.next(true);
    },
    async checkAndSubmit(callback, updateApplication = false) {
      try {
        await callback;
        // [Temp] Update application,for background fee calculation accordingly applicant count
        if (updateApplication) {
          await ApiService.put(`applications/${this.appId}`).catch((err) => {
            throw error;
          });
        }
      } catch (error) {
        throw error;
      } finally {
        this.saveAndExitSubmiting = false;
        this.footerSubmiting = false;
      }
    },
    updateFormArray(form) {
      let error = false;
      return new Promise(async (resolve, reject) => {
        for (const [index, item] of form.entries()) {
          const adminData = new FormData();

          if (item.legal_resident) {
            adminData.append('admin_user[legal_resident]', item.legal_resident);
          }

          if (item.diplomat) {
            adminData.append('admin_user[diplomat]', item.diplomat);
          }

          if (item.diplomat || item.legal_resident) {
            await ApiService.put(`applicants/${this.applicant.applicants.data[index].id}`, adminData).catch((err) => {
              error = true;
              reject(err);
            });
            if (error) return;
          }

          delete item.diplomat;
          delete item.legal_resident;
          for (const key of Object.keys(item)) {
            const record = item[key];
            const form = new FormData();
            const type = key === 'militaries' ? 'military' : key.slice(0, -1);

            for (const subKey of Object.keys(record)) {
              form.append(`${type}[${subKey}]`, item[key][subKey]);
            }

            if (record.id) {
              await ApiService.put(`${key}/${record.id}`, form).catch((err) => {
                error = true;
                reject(err);
              });
            } else {
              form.append(`${type}[application]`, {});
              form.append(`${type}[application_id]`, this.appId);
              form.append(`${type}[admin_user_id]`, this.applicant.applicants.data[index].id);
              await ApiService.post(`${key}`, form).catch((err) => {
                error = true;
                reject(err);
              });
            }
            if (error) return;
          }
        }

        resolve();
      });
    },
    updateForm(form) {
      return new Promise(async (resolve, reject) => {
        let error = false;
        for (const [index, applicant] of form.entries()) {
          const id = this.$v.applicantForm.$model[index].id;
          const formData = new FormData();

          Object.keys(applicant).forEach((key) => {
            formData.append(`admin_user[${key}]`, applicant[key]);
          });

          if (id) {
            formData.append(`admin_user[id]`, id);
            await ApiService.put(`applicants/${id}`, formData)
              .then((result) => {
                this.applicant.applicants.data.forEach((item) => {
                  if (item.id == id) {
                    Object.keys(applicant).forEach((key) => {
                      item.attributes[key] = applicant[key];
                    });
                  }
                });
              })
              .catch((err) => {
                error = true;
                reject(err);
              });
          } else {
            formData.append('admin_user[depend]', true);
            formData.append('admin_user[application_id]', this.appId);
            formData.append('admin_user[cooperative_id]', this.cooperative.id);
            await ApiService.post('applicants', formData)
              .then((res) => {
                this.$set(this.$v.applicantForm.$model[index], 'id', res.data.data.id);
                this.applicant.applicants.data.push(res.data.data);
              })
              .catch((err) => {
                error = true;
                reject(err);
              });
          }
          if (error) return;
        }
        resolve();
      });
    },
    async next(close = false) {
      if (this.step == 0) {
        if (!this.$v.applicantForm.$invalid) {
          this.footerSubmiting = true;

          await this.checkAndSubmit(this.updateForm(this.$v.applicantForm.$model), true);

          if (close) this.$emit('close');
          this.initForms(this.applicant);
          return (this.step += 1);
        } else {
          this.alertValidationFields(this.$v.applicantForm, []);
          this.$v.applicantForm.$touch();
          this.saveAndExitSubmiting = false;
        }
      } else if (this.step == 1) {
        if (!this.$v.applicantForm2.$invalid) {
          this.footerSubmiting = true;

          await this.checkAndSubmit(this.updateForm(this.$v.applicantForm2.$model));

          if (close) this.$emit('close');
          this.initForms(this.applicant);
          return (this.step += 1);
        } else {
          this.alertValidationFields(this.$v.applicantForm2, []);
          this.$v.applicantForm2.$touch();
          this.saveAndExitSubmiting = false;
        }
      } else if (this.step == 2) {
        this.footerSubmiting = true;
        await this.checkAndSubmit(this.updateFormArray(this.$v.applicantForm3.$model));

        if (close) this.$emit('close');
        $('.container').scrollTop(0);
        return (this.step += 1);
      } else if (this.step == 3) {
        if (!this.$v.applicantForm4.$invalid) {
          this.footerSubmiting = true;

          await this.checkAndSubmit(this.updateFormArray(this.$v.applicantForm4.$model));
          if (close) this.$emit('close');
          return (this.step += 1);
        } else {
          this.alertValidationFields(this.$v.applicantForm4, []);
          this.$v.applicantForm4.$touch();
          this.saveAndExitSubmiting = false;
        }
      } else if (this.step == 4) {
        if (!this.$v.applicantForm5.$invalid) {
          this.footerSubmiting = true;

          await this.checkAndSubmit(this.updateFormArray(this.$v.applicantForm5.$model));
          if (close) this.$emit('close');
          return (this.step += 1);
        } else {
          this.alertValidationFields(this.$v.applicantForm5, []);
          this.$v.applicantForm5.$touch();
          this.saveAndExitSubmiting = false;
        }
      }

      if (this.isLastStep) {
        this.footerSubmiting = true;
        await this.checkAndSubmit(this.updateFormArray(this.$v.applicantForm6.$model));
        return this.$emit('finish');
      }
    },
    back() {
      this.step -= 1;
    },
  },
  created() {
    this.initForms(this.applicant);
  },
};
</script>

<style scoped></style>
