<template>
  <div>
    <h3 class="text-dark mb-4">Installment Debt ?</h3>
    <div v-if="!v.applicantForm2.$model.installment_debts.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm2.$model.installment_debts"
      :key="`installment_debts${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Principal Balance Remaining
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.balance"
            :state="validateState(v.applicantForm2.installment_debts.$each[index].balance)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Monthly Payment
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="number"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.monthly_payments"
            :state="validateState(v.applicantForm2.installment_debts.$each[index].monthly_payments)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'installment_debts', item.id, 'liabilities')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('installment_debts', 'liabilities')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Any credit card debts ?</h3>
    <div v-if="!v.applicantForm2.$model.credit_card_debts.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm2.$model.credit_card_debts"
      :key="`credit_card_debts${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Principal Balance Remaining
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.balance"
            :state="validateState(v.applicantForm2.credit_card_debts.$each[index].balance)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Monthly Payment
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="number"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.monthly_payments"
            :state="validateState(v.applicantForm2.credit_card_debts.$each[index].monthly_payments)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'credit_card_debts', item.id, 'liabilities')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('credit_card_debts', 'liabilities')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Any Mortgage ?</h3>
    <div v-if="!v.applicantForm2.$model.mortgages.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm2.$model.mortgages"
      :key="`mortgages${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Principal Balance Remaining
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.balance"
            :state="validateState(v.applicantForm2.mortgages.$each[index].balance)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Monthly Payment
            <span class="text-danger">*</span>
          </template>

          <cent-to-dollar-input
            type="number"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.monthly_payments"
            :state="validateState(v.applicantForm2.mortgages.$each[index].monthly_payments)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'mortgages', item.id, 'liabilities')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('mortgages', 'liabilities')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Car loan ?</h3>
    <div v-if="!v.applicantForm2.$model.automobiles.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm2.$model.automobiles"
      :key="`automobiles${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Principal Balance Remaining
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.balance"
            :state="validateState(v.applicantForm2.automobiles.$each[index].balance)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Monthly Payment
            <span class="text-danger">*</span>
          </template>

          <cent-to-dollar-input
            type="number"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.monthly_payments"
            :state="validateState(v.applicantForm2.automobiles.$each[index].monthly_payments)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'automobiles', item.id, 'liabilities')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('automobiles', 'liabilities')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Other ?</h3>
    <div v-if="!v.applicantForm2.$model.other_liabilities.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm2.$model.other_liabilities"
      :key="`other_liabilities${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Principal Balance Remaining
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.balance"
            :state="validateState(v.applicantForm2.other_liabilities.$each[index].balance)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Monthly Payment
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            type="number"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.monthly_payments"
            :state="validateState(v.applicantForm2.other_liabilities.$each[index].monthly_payments)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'other_liabilities', item.id, 'liabilities')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('other_liabilities', 'liabilities')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>
  </div>
</template>

<script>
import ApiService from '@/helpers/api.service';
import CentToDollarInput from '../../../form/CentToDollarInput';

export default {
  name: 'TotalLiabitilitesForm',
  components: { CentToDollarInput },
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    applicationId: {
      type: [String, Number],
      required: true,
    },
    applicants: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      defaultassets: {
        installment_debts: {
          id: '',
          admin_user_id: '',
          balance: '',
          monthly_payments: '',
        },
        credit_card_debts: {
          id: '',
          admin_user_id: '',
          balance: '',
          monthly_payments: '',
        },
        mortgages: {
          id: '',
          admin_user_id: '',
          balance: '',
          monthly_payments: '',
        },
        automobiles: {
          id: '',
          admin_user_id: '',
          balance: '',
          monthly_payments: '',
        },
        other_liabilities: {
          id: '',
          admin_user_id: '',
          balance: '',
          monthly_payments: '',
        },
      },
    };
  },
  computed: {
    applicantListOptions() {
      if (this.applicants) {
        const data = this.applicants.map((applicant, index) => ({
          text: `Applicant ${index + 1}`,
          value: applicant.id,
        }));
        data.push({
          text: `Joint`,
          value: null,
        });
        return data;
      }
      return [];
    },
  },
  methods: {
    filterApplicationsList(data, key, value, type = 'any') {
      if (type == 'any') return this.applicantListOptions;
      if (type == 'unique') {
        return this.applicantListOptions.filter((a) => {
          const exists = data.find((d) => d[key] == a.value);
          return !exists || a.value == value;
        });
      }
      if (type == 'unique_without_joint') {
        return this.applicantListOptions
          .filter((a) => a.value)
          .filter((a) => {
            const exists = data.find((d) => d[key] == a.value);
            return !exists || a.value == value;
          });
      }
      if (type == 'without_joint') {
        return this.applicantListOptions.filter((a) => a.value);
      }
    },
    addItem(group) {
      const defItem = Object.assign({}, this.defaultassets[group]);
      defItem.admin_user_id = -1;
      defItem.key = this.generateUid();
      this.v.applicantForm2.$model[group].push(defItem);
    },
    deleteItem(index, group, id) {
      this.v.applicantForm2.$model[group].splice(index, 1);

      if (id) {
        ApiService.delete(`${group}/${id}`);
      }
    },
  },
  created() {
    const params = {
      elasticsearch: true,
      per: Math.pow(10, 12),
      where: {
        application_id: this.applicationId,
      },
    };

    Object.keys(this.v.applicantForm2.$model).forEach((key) => {
      ApiService.query(key, { params }).then((res) => {
        this.v.applicantForm2.$model[key] = res.data.data.map((elRes) => {
          const resD = Object.assign({}, this.defaultassets[key]);
          Object.keys(resD).map((k) => {
            resD[k] = elRes.attributes[k];
            return k;
          });
          resD.id = elRes.id;
          resD.admin_user_id = elRes.attributes.admin_user.data ? elRes.attributes.admin_user.data.id : null;
          resD.key = this.generateUid();
          return resD;
        });
      });
    });
  },
};
</script>
