<template>
  <div>
    <div v-for="(applicant, index) in v.applicantForm4.$model" :key="index">
      <h2 class="h1 text-dark text-center mb-12">Residential Information for applicant {{ index + 1 }}</h2>

      <b-form-group>
        <template v-slot:label>
          Do you currently have a Landlord?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input type="radio" :name="`${index}-landlords_have`" v-model="applicant.landlords.have" :value="true" />
            <span />
            Yes
          </label>
          <label class="radio">
            <input type="radio" :name="`${index}-landlords_have1`" v-model="applicant.landlords.have" :value="false" />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.landlords.have === true">
        <b-form-group>
          <template v-slot:label>
            Landlord Name
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.landlords.name"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Landlord Phone
          </template>
          <b-form-input
            type="text"
            v-mask="'(###)###-####'"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.landlords.phone"
            :state="validateState(v.applicantForm4.$each[index].landlords.phone)"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Landlord Email
          </template>
          <b-form-input
            type="email"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm4.$each[index].landlords.email)"
            v-model="applicant.landlords.email"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of tenancy (Start)
          </template>
          <date-picker
            :id="`${index}_landlords.tenancy_start_date`"
            v-model="applicant.landlords.tenancy_start_date"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of tenancy (End)
          </template>
          <date-picker
            :id="`${index}_landlords.tenancy_end_date`"
            :min="applicant.landlords.tenancy_start_date"
            v-model="applicant.landlords.tenancy_end_date"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Landlord Address
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.landlords.address"
          />
        </b-form-group>
      </div>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-form-group>
        <template v-slot:label>
          Do you have a prior landlord ?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-prior_landlord`"
              v-model="applicant.prior_landlords.have"
              :value="true"
            />
            <span />
            Yes
          </label>
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-prior_landlord1`"
              v-model="applicant.prior_landlords.have"
              :value="false"
            />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.prior_landlords.have === true">
        <b-form-group>
          <template v-slot:label>
            Landlord Name
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.prior_landlords.name"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Landlord Phone
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.prior_landlords.phone"
            v-mask="'(###)###-####'"
            :state="validateState(v.applicantForm4.$each[index].prior_landlords.phone)"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Landlord Email
          </template>
          <b-form-input
            type="email"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm4.$each[index].prior_landlords.email)"
            v-model="applicant.prior_landlords.email"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Landlord Tenancy Start Date
          </template>
          <date-picker
            :id="`${index}_prior_landlords.tenancy_start_date`"
            v-model="applicant.prior_landlords.tenancy_start_date"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Landlord Tenancy End Date
          </template>
          <date-picker
            :id="`${index}_prior_landlords.tenancy_end_date`"
            :min="applicant.prior_landlords.tenancy_start_date"
            v-model="applicant.prior_landlords.tenancy_end_date"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Landlord Address
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.prior_landlords.address"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../../helpers/api.service';

export default {
  name: 'ResidentalForm',
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    applicants: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      defaultKeys: {
        landlords: {},
        prior_landlords: {},
      },
    };
  },
  created() {
    this.v.applicantForm4.$model.forEach((item, index) => {
      const params = {
        elasticsearch: true,
        per: Math.pow(10, 12),
        where: {
          admin_user_id: this.applicants[index].id,
        },
      };

      Object.keys(this.defaultKeys).forEach((key) => {
        ApiService.query(key, { params }).then((res) => {
          this.v.applicantForm4.$model[index][key] = res.data.data.length
            ? {
                ...res.data.data[0].attributes,
                id: res.data.data[0].id,
                type: res.data.data[0].type,
              }
            : this.v.applicantForm4.$model[index][key];
        });
      });
    });
  },
};
</script>
