<template>
  <div>
    <h2 class="h1 text-dark text-center mb-12">Expenses (per month)</h2>
    <h3 class="text-dark mb-4">Vehicle Lease and Loan Payments</h3>
    <div v-if="!v.applicantForm4.$model.vehicle_leases.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm4.$model.vehicle_leases"
      :key="`vehicle_leases${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Assigned to
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(
                v.applicantForm4.$model.vehicle_leases,
                'admin_user_id',
                item.admin_user_id,
                'unique'
              )
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.amount"
            :state="validateState(v.applicantForm4.vehicle_leases.$each[index].amount)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'vehicle_leases', item.id, 'expenses')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div v-if="v.applicantForm4.$model.vehicle_leases.length < applicantListOptions.length" class="text-right">
      <b-btn @click="addItem('vehicle_leases', 'expenses')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Personal Loans</h3>
    <div v-if="!v.applicantForm4.$model.personal_loans.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm4.$model.personal_loans"
      :key="`personal_loans${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Assigned to
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(
                v.applicantForm4.$model.personal_loans,
                'admin_user_id',
                item.admin_user_id,
                'unique'
              )
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.amount"
            :state="validateState(v.applicantForm4.personal_loans.$each[index].amount)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'personal_loans', item.id, 'expenses')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div v-if="v.applicantForm4.$model.personal_loans.length < applicantListOptions.length" class="text-right">
      <b-btn @click="addItem('personal_loans', 'expenses')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Student Loans</h3>
    <div v-if="!v.applicantForm4.$model.student_loans.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm4.$model.student_loans"
      :key="`student_loans${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Assigned to
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            :options="
              filterApplicationsList(
                v.applicantForm4.$model.student_loans,
                'admin_user_id',
                item.admin_user_id,
                'unique_without_joint'
              )
            "
            v-model="item.admin_user_id"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.amount"
            :state="validateState(v.applicantForm4.student_loans.$each[index].amount)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'student_loans', item.id, 'expenses')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div v-if="v.applicantForm4.$model.student_loans.length < applicantListOptions.length - 1" class="text-right">
      <b-btn @click="addItem('student_loans', 'expenses')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">
      Mortgage <i :id="`mortgage-info`" class="ml-2 cursor-pointer text-danger fa fa-exclamation-circle"></i>
    </h3>
    <b-tooltip :target="`mortgage-info`" placement="top">
      Please do not include your current mortage for the application.
    </b-tooltip>
    <div v-if="!v.applicantForm4.$model.mortgage_expenses.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm4.$model.mortgage_expenses"
      :key="`mortgage_expenses${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Assigned to
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            :options="
              filterApplicationsList(
                v.applicantForm4.$model.mortgage_expenses,
                'admin_user_id',
                item.admin_user_id,
                'unique'
              )
            "
            v-model="item.admin_user_id"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.amount"
            :state="validateState(v.applicantForm4.mortgage_expenses.$each[index].amount)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'mortgage_expenses', item.id, 'expenses')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div v-if="v.applicantForm4.$model.mortgage_expenses.length < applicantListOptions.length" class="text-right">
      <b-btn @click="addItem('mortgage_expenses', 'expenses')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Alimony</h3>
    <div v-if="!v.applicantForm4.$model.alimonies.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm4.$model.alimonies"
      :key="`alimonies${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Assigned to
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(
                v.applicantForm4.$model.alimonies,
                'admin_user_id',
                item.admin_user_id,
                'unique_without_joint'
              )
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.amount"
            :state="validateState(v.applicantForm4.alimonies.$each[index].amount)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'alimonies', item.id, 'expenses')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div v-if="v.applicantForm4.$model.alimonies.length < applicantListOptions.length - 1" class="text-right">
      <b-btn @click="addItem('alimonies', 'expenses')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Child Support</h3>
    <div v-if="!v.applicantForm4.$model.child_supports.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm4.$model.child_supports"
      :key="`child_supports${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Assigned to
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(
                v.applicantForm4.$model.child_supports,
                'admin_user_id',
                item.admin_user_id,
                'unique_without_joint'
              )
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.amount"
            :state="validateState(v.applicantForm4.child_supports.$each[index].amount)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'child_supports', item.id, 'expenses')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div v-if="v.applicantForm4.$model.child_supports.length < applicantListOptions.length - 1" class="text-right">
      <b-btn @click="addItem('child_supports', 'expenses')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Other Monthly Expenses</h3>
    <div v-if="!v.applicantForm4.$model.other_expenses.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm4.$model.other_expenses"
      :key="`other_expenses${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Assigned to
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(
                v.applicantForm4.$model.other_expenses,
                'admin_user_id',
                item.admin_user_id,
                'unique'
              )
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.amount"
            :state="validateState(v.applicantForm4.other_expenses.$each[index].amount)"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'other_expenses', item.id, 'expenses')"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div v-if="v.applicantForm4.$model.other_expenses.length < applicantListOptions.length" class="text-right">
      <b-btn @click="addItem('other_expenses', 'expenses')" variant="primary" class="font-weight-bolder">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>
  </div>
</template>

<script>
import ApiService from '@/helpers/api.service';
import CentToDollarInput from '../../../form/CentToDollarInput';

export default {
  name: 'ExpensesForm',
  components: { CentToDollarInput },
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    applicationId: {
      type: [String, Number],
      required: true,
    },
    applicants: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      defaultassets: {
        vehicle_leases: {
          id: '',
          admin_user_id: '',
          amount: '',
        },
        personal_loans: {
          id: '',
          admin_user_id: '',
          amount: '',
        },
        student_loans: {
          id: '',
          admin_user_id: '',
          amount: '',
        },
        mortgage_expenses: {
          id: '',
          admin_user_id: '',
          amount: '',
        },
        alimonies: {
          id: '',
          admin_user_id: '',
          amount: '',
        },
        child_supports: {
          id: '',
          admin_user_id: '',
          amount: '',
        },
        other_expenses: {
          id: '',
          admin_user_id: '',
          amount: '',
        },
      },
    };
  },
  computed: {
    applicantListOptions() {
      if (this.applicants) {
        const data = this.applicants.map((applicant, index) => ({
          text: `Applicant ${index + 1}`,
          value: applicant.id,
        }));
        data.push({
          text: `Joint`,
          value: null,
        });
        return data;
      }
      return [];
    },
  },
  methods: {
    filterApplicationsList(data, key, value, type = 'any') {
      if (type == 'any') return this.applicantListOptions;
      if (type == 'unique') {
        return this.applicantListOptions.filter((a) => {
          const exists = data.find((d) => d[key] == a.value);
          return !exists || a.value == value;
        });
      }
      if (type == 'unique_without_joint') {
        return this.applicantListOptions
          .filter((a) => a.value)
          .filter((a) => {
            const exists = data.find((d) => d[key] == a.value);
            return !exists || a.value == value;
          });
      }
      if (type == 'without_joint') {
        return this.applicantListOptions.filter((a) => a.value);
      }
    },
    addItem(group) {
      const defItem = Object.assign({}, this.defaultassets[group]);
      defItem.admin_user_id = -1;
      defItem.key = this.generateUid();
      this.v.applicantForm4.$model[group].push(defItem);
    },
    deleteItem(index, group, id) {
      this.v.applicantForm4.$model[group].splice(index, 1);

      if (id) {
        ApiService.delete(`${group}/${id}`);
      }
    },
  },
  created() {
    const params = {
      elasticsearch: true,
      per: Math.pow(10, 12),
      where: {
        application_id: this.applicationId,
      },
    };

    Object.keys(this.v.applicantForm4.$model).forEach((key) => {
      ApiService.query(key, { params }).then((res) => {
        this.v.applicantForm4.$model[key] = res.data.data.map((elRes) => {
          const resD = Object.assign({}, this.defaultassets[key]);
          Object.keys(resD).map((k) => {
            resD[k] = elRes.attributes[k];
            return k;
          });
          resD.id = elRes.id;
          resD.admin_user_id = elRes.attributes.admin_user.data ? elRes.attributes.admin_user.data.id : null;
          resD.key = this.generateUid();
          return resD;
        });
      });
    });
  },
};
</script>
