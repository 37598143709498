<template>
  <div>
    <KTWizardStepContent :step-number="1" title="Introduction">
      <template v-slot:save-btn>
        <b-btn variant="light-primary" size="lg" @click="onCloseAndSave()">
          Save and Exit
        </b-btn>
      </template>
      <template v-slot:nav>
        <KTWizardStepContentNavList>
          <KTWizardStepContentNavItem
            :orderNumber="1"
            title="Introduction"
            :is-checked="stepStatus > 0"
            :is-current="stepStatus === 0"
          />
          <KTWizardStepContentNavItem
            :orderNumber="2"
            title="Document needed"
            :is-checked="stepStatus > 1"
            :is-current="stepStatus === 1"
          />
          <!-- <KTWizardStepContentNavItem
              :orderNumber="3"
              title="Supplementals"
              :is-checked="stepStatus > 2"
               :is-current="stepStatus === 2"
          /> -->
        </KTWizardStepContentNavList>
      </template>
      <template v-slot:body>
        <div v-if="stepStatus === 0">
          <h2 class="h1 text-dark text-center mb-12">App Express Introductory Language</h2>

          <p class="lead">
            Welcome to AppExpress. In order to promptly complete your application to purchase or own shares in
            {{ 1 }} Cooperative, you must complete the application in its entirety. At the inception of the application
            process you will be prompted to indicate how many applicants will be applying. Even if you are husband and
            wife you will need to each fill out an application. A code will be provided for the second person or third
            person, etc. completing the application. The reason for this is that various reports such as credit reports
            require separate application and authorizations to complete the application. However, you may upload the
            same documents if applicable to both of you, such as tax returns. In order to complete the application you
            will need to download the certain forms (click here to download), sign, and scan them as pdf files to be
            uploaded when prompted. You will also need to upload the following additional documents as pdfs when
            prompted:
          </p>
        </div>
        <div v-if="stepStatus === 1">
          <h2 class="h1 text-dark mb-12">List of documents</h2>
          <p class="p text-dark mb-12">Contract of Sale</p>
          <p class="p text-dark mb-12">Landlord Reference Letter</p>
          <p class="p text-dark mb-12">Personal Reference Letters</p>
          <p class="p text-dark mb-12">Professional Reference Letters</p>
          <p class="p text-dark mb-12">Bank/Investment Account Statements (last two months for all accounts)</p>
          <p class="p text-dark mb-12">Pay Stubs (last two)</p>
          <p class="p text-dark mb-12">Loan Application</p>
          <p class="p text-dark mb-12">Tax Returns (last two years)</p>
          <p class="p text-dark mb-12">W-2 (last two)</p>
          <p class="p text-dark mb-12">Commitment Letter</p>
          <p class="p text-dark mb-12">Photo Identification</p>

          <h2 v-if="supplementals.length" class="p text-dark mb-12">Download Supplementary Documents Now</h2>
          <p
            v-for="doc in supplementals"
            :key="doc.id"
            class="p text-dark mb-12 d-flex align-items-center justify-content-between"
          >
            {{ doc.name }}
            <a v-if="doc.file" :href="doc.file" class="mr-5" target="_blank">
              <i class="flaticon2-download"></i>
            </a>
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <b-btn
          v-if="stepStatus > 0"
          variant="light-primary"
          class="font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3"
          @click="back()"
        >
          <span class="svg-icon svg-icon-md mr-2">
            <inline-svg src="media/svg/icons/Navigation/Left-2.svg"></inline-svg>
          </span>
          Back
        </b-btn>
        <b-btn
          v-if="stepStatus < 1"
          variant="primary"
          class="font-weight-bolder font-size-h6 pl-8 pr-4 py-4 ml-auto"
          @click="next()"
        >
          Next
          <span class="svg-icon svg-icon-md ml-2">
            <inline-svg src="media/svg/icons/Navigation/Right-2.svg"></inline-svg>
          </span>
        </b-btn>
        <b-btn
          v-if="stepStatus === 1"
          variant="primary"
          class="font-weight-bolder font-size-h6 px-8 py-4"
          @click="onFinish()"
        >
          Finish
        </b-btn>
      </template>
    </KTWizardStepContent>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'application-intro',
  props: {
    cooperative: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    stepStatus: 0,
  }),
  computed: {
    ...mapGetters({
      supplementals: 'supplemental/items',
    }),
  },
  watch: {
    step: {
      handler() {
        $('.wizard-custom-content-body').scrollTop(0);
      },
    },
  },
  methods: {
    ...mapActions({
      getSupplemental: 'supplemental/index',
    }),
    next() {
      this.stepStatus += 1;
    },
    back() {
      this.stepStatus -= 1;
    },
    onCloseAndSave() {
      this.$emit('onSaveAndClose');
    },
    onFinish() {
      this.$emit('onFinish');
    },
  },
  mounted() {
    this.getSupplemental({
      where: {
        cooperative_id: this.cooperative.id,
      },
    });
  },
};
</script>
<style lang="scss" scoped></style>
