<template>
  <div>
    <h3 class="text-dark mb-4">Сash assets</h3>
    <div v-if="!v.applicantForm.$model.cashes.length" class="font-italic">
      Empty
    </div>
    <b-row v-for="(item, index) in v.applicantForm.$model.cashes" :key="`cashes${item.key}`" class="position-relative">
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(v.applicantForm.$model.cashes, 'admin_user_id', item.admin_user_id, 'unique')
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount Balance
            <span class="text-danger">*</span>
          </template>

          <cent-to-dollar-input
            v-model="item.amount"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm.cashes.$each[index].amount)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'cashes', item.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>

    <div v-if="v.applicantForm.$model.cashes.length < applicantListOptions.length" class="text-right">
      <b-btn @click="addItem('cashes')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Certificate of deposits</h3>
    <div v-if="!v.applicantForm.$model.certificate_deposites.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm.$model.certificate_deposites"
      :key="`certificate_deposites${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(
                v.applicantForm.$model.certificate_deposites,
                'admin_user_id',
                item.admin_user_id,
                'unique'
              )
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount Balance
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.amount"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm.certificate_deposites.$each[index].amount)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'certificate_deposites', item.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div v-if="v.applicantForm.$model.certificate_deposites.length < applicantListOptions.length" class="text-right">
      <b-btn @click="addItem('certificate_deposites')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Securities</h3>
    <div v-if="!v.applicantForm.$model.securities.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm.$model.securities"
      :key="`securities${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(v.applicantForm.$model.securities, 'admin_user_id', item.admin_user_id, 'unique')
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Amount Balance
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.amount"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm.securities.$each[index].amount)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'securities', item.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div v-if="v.applicantForm.$model.securities.length < applicantListOptions.length" class="text-right">
      <b-btn @click="addItem('securities')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Any real estate</h3>
    <div v-if="!v.applicantForm.$model.real_estates.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm.$model.real_estates"
      :key="`real_estates${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Percentage Ownership Interest
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            :min="0"
            :max="100"
            :state="validateState(v.applicantForm.real_estates.$each[index].percentage)"
            :formatter="percentageFormatter"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.percentage"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Type of Property
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm.real_estates.$each[index].type_of_property)"
            v-model="item.type_of_property"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Location of Property
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm.real_estates.$each[index].location)"
            v-model="item.location"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Estimated Value of Property
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.estimated_value"
            :state="validateState(v.applicantForm.real_estates.$each[index].estimated_value)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Mortgage Balance
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.mortgage_balance"
            :state="validateState(v.applicantForm.real_estates.$each[index].mortgage_balance)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'real_estates', item.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('real_estates')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Any Vehicles</h3>
    <div v-if="!v.applicantForm.$model.vehicles.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm.$model.vehicles"
      :key="`vehicles${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Make
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.make"
            :state="validateState(v.applicantForm.vehicles.$each[index].make)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Model
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.model"
            :state="validateState(v.applicantForm.vehicles.$each[index].model)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Year
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            v-mask="defaultMask.year"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.year"
            :state="validateState(v.applicantForm.vehicles.$each[index].year)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Loan Balance
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.loan_balance"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm.vehicles.$each[index].loan_balance)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Estimated Value
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.estimated_value"
            :state="validateState(v.applicantForm.vehicles.$each[index].estimated_value)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'vehicles', item.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('vehicles')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Any IRA, 401k or other retirement account ?</h3>
    <div v-if="!v.applicantForm.$model.individual_retirement_accounts.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm.$model.individual_retirement_accounts"
      :key="`individual_retirement_accounts${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(
                v.applicantForm.$model.individual_retirement_accounts,
                'admin_user_id',
                item.admin_user_id,
                'without_joint'
              )
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Type
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.ira_type"
            :state="validateState(v.applicantForm.individual_retirement_accounts.$each[index].ira_type)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Account Balance
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.balance"
            :state="validateState(v.applicantForm.individual_retirement_accounts.$each[index].balance)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'individual_retirement_accounts', item.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('individual_retirement_accounts')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Any Life Insurance ?</h3>
    <div v-if="!v.applicantForm.$model.life_insurances.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm.$model.life_insurances"
      :key="`life_insurances${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Policy Holder
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="
              filterApplicationsList(
                v.applicantForm.$model.life_insurances,
                'admin_user_id',
                item.admin_user_id,
                'without_joint'
              )
            "
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Policy Name
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.name"
            :state="validateState(v.applicantForm.life_insurances.$each[index].name)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Policy Type
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.life_insurance_type"
            :state="validateState(v.applicantForm.life_insurances.$each[index].life_insurance_type)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Policy Benefit Amount
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.benefit_amount"
            :state="validateState(v.applicantForm.life_insurances.$each[index].benefit_amount)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Policy Beneficiary
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.beneficiary"
            :state="validateState(v.applicantForm.life_insurances.$each[index].beneficiary)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'life_insurances', item.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('life_insurances')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Do you or have you ever owned a business?</h3>
    <div v-if="!v.applicantForm.$model.businesses.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm.$model.businesses"
      :key="`businesses${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Business Owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Business Name
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.name"
            :state="validateState(v.applicantForm.businesses.$each[index].name)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Business Type
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.business_type"
            :state="validateState(v.applicantForm.businesses.$each[index].business_type)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Percentage Ownership Interest
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            :min="0"
            :max="100"
            :formatter="percentageFormatter"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.percentage"
            :state="validateState(v.applicantForm.businesses.$each[index].percentage)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group :state="validateState(v.applicantForm.businesses.$each[index].date_of_formation)">
          <template v-slot:label>
            Date of formation
            <span class="text-danger">*</span>
          </template>
          <date-picker :id="`${index}_date_of_formation`" v-model="item.date_of_formation"></date-picker>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group :state="validateState(v.applicantForm.businesses.$each[index].date_of_termination)">
          <template v-slot:label>
            Date of termination
            <span class="text-danger">*</span>
          </template>
          <date-picker :id="`${index}_date_of_termination`" v-model="item.date_of_termination"></date-picker>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Estimated value of business
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.estimated_value"
            :state="validateState(v.applicantForm.businesses.$each[index].estimated_value)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Estimated amount of liabilities
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.estimated_amount_of_liabilities"
            :state="validateState(v.applicantForm.businesses.$each[index].estimated_amount_of_liabilities)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Estimated amount of assets
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.estimated_amount_of_assets"
            :state="validateState(v.applicantForm.businesses.$each[index].estimated_amount_of_assets)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'businesses', item.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('businesses')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>

    <h3 class="text-dark mb-4">Personal property ?</h3>
    <div v-if="!v.applicantForm.$model.properties.length" class="font-italic">
      Empty
    </div>
    <b-row
      v-for="(item, index) in v.applicantForm.$model.properties"
      :key="`properties${item.key}`"
      class="position-relative"
    >
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Property owner
            <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="item.admin_user_id"
            :options="applicantListOptions"
            class="form-control form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Item description
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="item.description"
            :state="validateState(v.applicantForm.properties.$each[index].description)"
          />
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <template v-slot:label>
            Estimated value of item
            <span class="text-danger">*</span>
          </template>
          <cent-to-dollar-input
            v-model="item.estimated_value"
            :state="validateState(v.applicantForm.properties.$each[index].estimated_value)"
            classes="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
          />
        </b-form-group>
      </b-col>

      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-btn
        variant="danger"
        size="sm"
        class="btn-remove btn-icon btn-circle position-absolute right-0"
        @click="deleteItem(index, 'properties', item.id)"
      >
        <i class="fas fa-times" />
      </b-btn>
    </b-row>
    <div class="text-right">
      <b-btn @click="addItem('properties')" variant="primary" class="font-weight-bolder mb-10">
        <i class="la la-plus"></i>
        Add
      </b-btn>
    </div>
  </div>
</template>

<script>
import ApiService from '@/helpers/api.service';
import CentToDollarInput from '../../../form/CentToDollarInput';

export default {
  name: 'TotalAssetsForm',
  components: { CentToDollarInput },
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    applicationId: {
      type: [String, Number],
      required: true,
    },
    applicants: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      defaultassets: {
        cashes: {
          id: '',
          amount: '',
          admin_user_id: '',
        },
        certificate_deposites: {
          id: '',
          amount: '',
          admin_user_id: '',
        },
        securities: {
          id: '',
          amount: '',
          admin_user_id: '',
        },
        real_estates: {
          id: '',
          admin_user_id: '',
          percentage: '',
          type_of_property: '',
          location: '',
          estimated_value: '',
          mortgage_balance: '',
        },
        vehicles: {
          id: '',
          admin_user_id: '',
          make: '',
          model: '',
          year: '',
          loan_balance: '',
          estimated_value: '',
        },
        individual_retirement_accounts: {
          id: '',
          admin_user_id: '',
          ira_type: '',
          balance: '',
        },
        life_insurances: {
          id: '',
          admin_user_id: '',
          name: '',
          life_insurance_type: '',
          benefit_amount: '',
          beneficiary: '',
        },
        businesses: {
          id: '',
          admin_user_id: '',
          name: '',
          business_type: '',
          percentage: '',
          date_of_formation: '',
          date_of_termination: '',
          estimated_value: '',
          estimated_amount_of_liabilities: '',
          estimated_amount_of_assets: '',
        },
        properties: {
          id: '',
          admin_user_id: '',
          description: '',
          estimated_value: '',
        },
      },
    };
  },
  computed: {
    applicantListOptions() {
      if (this.applicants) {
        const data = this.applicants.map((applicant, index) => ({
          text: `Applicant ${index + 1}`,
          value: applicant.id,
        }));
        data.push({
          text: `Joint`,
          value: null,
        });
        return data;
      }
      return [];
    },
  },
  methods: {
    percentageFormatter(e) {
      return parseFloat(e) > 100 ? 100 : e;
    },
    filterApplicationsList(data, key, value, type = 'any') {
      if (type == 'any') return this.applicantListOptions;
      if (type == 'unique') {
        return this.applicantListOptions.filter((a) => {
          const exists = data.find((d) => d[key] == a.value);
          return !exists || a.value == value;
        });
      }
      if (type == 'unique_without_joint') {
        return this.applicantListOptions
          .filter((a) => a.value)
          .filter((a) => {
            const exists = data.find((d) => d[key] == a.value);
            return !exists || a.value == value;
          });
      }
      if (type == 'without_joint') {
        return this.applicantListOptions.filter((a) => a.value);
      }
    },
    addItem(group) {
      const defItem = Object.assign({}, this.defaultassets[group]);
      defItem.admin_user_id = -1;
      defItem.key = this.generateUid();
      this.v.applicantForm.$model[group].push(defItem);
    },
    deleteItem(index, group, id) {
      this.v.applicantForm.$model[group].splice(index, 1);

      if (id) {
        ApiService.delete(`${group}/${id}`);
      }
    },
  },
  created() {
    const params = {
      elasticsearch: true,
      per: Math.pow(10, 12),
      where: {
        application_id: this.applicationId,
      },
    };

    Object.keys(this.v.applicantForm.$model).forEach((key) => {
      ApiService.query(key, { params }).then((res) => {
        this.v.applicantForm.$model[key] = res.data.data.map((elRes) => {
          const resD = Object.assign({}, this.defaultassets[key]);
          Object.keys(resD).map((k) => {
            resD[k] = elRes.attributes[k];
            return k;
          });
          resD.id = elRes.id;
          resD.admin_user_id = elRes.attributes.admin_user.data ? elRes.attributes.admin_user.data.id : null;
          resD.key = this.generateUid();
          return resD;
        });
      });
    });
  },
};
</script>
