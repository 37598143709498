<template>
  <div>
    <div v-for="(applicant, index) in v.applicantForm5.$model" :key="index">
      <h2 class="h1 text-dark text-center mb-12">Employment Information for applicant {{ index + 1 }}</h2>

      <b-form-group>
        <template v-slot:label>
          Are you currently employed ?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-employed`"
              v-model="applicant.current_employments.have"
              :value="true"
            />
            <span />
            Yes
          </label>
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-employed1`"
              v-model="applicant.current_employments.have"
              :value="false"
            />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.current_employments.have">
        <b-form-group>
          <template v-slot:label>
            Employer Name
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.current_employments.name"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Employer Address
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.current_employments.address"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Supervisor Name
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.current_employments.supervisor_name"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Supervisor telephone number
          </template>
          <b-form-input
            type="text"
            v-mask="'(###)###-####'"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.current_employments.supervisor_phone"
            :state="validateState(v.applicantForm5.$each[index].current_employments.supervisor_phone)"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Supervisor e-mail address
          </template>
          <b-form-input
            type="email"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm5.$each[index].current_employments.supervisor_email)"
            v-model="applicant.current_employments.supervisor_email"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of employment (Start)
          </template>
          <date-picker
            :id="`${index}_current_employments.start_date`"
            v-model="applicant.current_employments.start_date"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of employment (End)
          </template>
          <date-picker
            :id="`${index}_current_employments.end_date`"
            v-model="applicant.current_employments.end_date"
            :min="applicant.current_employments.start_date"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Job Title
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.current_employments.job_title"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Responsibilities
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.current_employments.responsibilities"
          />
        </b-form-group>
      </div>
      <b-col lg="12">
        <div class="separator separator-solid separator-secondary separator-border-2 mb-8" />
      </b-col>
      <b-form-group>
        <template v-slot:label>
          Were you previously employed?
        </template>
        <div class="radio-inline">
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-employed_previosly`"
              v-model="applicant.previous_employments.have"
              :value="true"
            />
            <span />
            Yes
          </label>
          <label class="radio">
            <input
              type="radio"
              :name="`${index}-employed_previosly1`"
              v-model="applicant.previous_employments.have"
              :value="false"
            />
            <span />
            No
          </label>
        </div>
      </b-form-group>
      <div v-if="applicant.previous_employments.have">
        <b-form-group>
          <template v-slot:label>
            Employer Name
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.previous_employments.name"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Employer Address
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.previous_employments.address"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Supervisor Name
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.previous_employments.supervisor_name"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Supervisor telephone number
          </template>
          <b-form-input
            type="text"
            v-mask="'(###)###-####'"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.previous_employments.supervisor_phone"
            :state="validateState(v.applicantForm5.$each[index].previous_employments.supervisor_phone)"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Supervisor e-mail address
          </template>
          <b-form-input
            type="email"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            :state="validateState(v.applicantForm5.$each[index].previous_employments.supervisor_email)"
            v-model="applicant.previous_employments.supervisor_email"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of employment (Start)
          </template>
          <date-picker
            :id="`${index}_previous_employments.start_date`"
            v-model="applicant.previous_employments.start_date"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Dates of employment (End)
          </template>
          <date-picker
            :id="`${index}_previous_employments.end_date`"
            :min="applicant.previous_employments.start_date"
            v-model="applicant.previous_employments.end_date"
          ></date-picker>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Job Title
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.previous_employments.job_title"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Responsibilities
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.previous_employments.responsibilities"
          />
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Reason For Leaving
          </template>
          <b-form-input
            type="text"
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            v-model="applicant.previous_employments.reason_for_leaving"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../../helpers/api.service';

export default {
  name: 'EmploymentForm',
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    applicants: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      defaultKeys: {
        current_employments: {},
        previous_employments: {},
      },
    };
  },
  created() {
    this.v.applicantForm5.$model.forEach((item, index) => {
      const params = {
        elasticsearch: true,
        per: Math.pow(10, 12),
        where: {
          admin_user_id: this.applicants[index].id,
        },
      };

      Object.keys(this.defaultKeys).forEach((key) => {
        ApiService.query(key, { params }).then((res) => {
          this.v.applicantForm5.$model[index][key] = res.data.data.length
            ? {
                ...res.data.data[0].attributes,
                id: res.data.data[0].id,
                type: res.data.data[0].type,
              }
            : this.v.applicantForm5.$model[index][key];
        });
      });
    });
  },
};
</script>
