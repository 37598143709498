<template>
  <KTWizardStepContent :step-number="5" title="Payments" class="asasd">
    <template v-slot:nav> </template>

    <template v-slot:body-custom>
      <b-row class="justify-content-between" v-if="showSuccessPage === false">
        <b-col md="7">
          <div class="p-9">
            <h3 class="text-primary mb-7">
              Purchaser Fees Payment Page for
              <u>
                <b>{{ cooperative.attributes.company_name }}</b>
              </u>
            </h3>
            <b-form-group>
              <template v-slot:label>
                Name on Card
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                type="text"
                class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
                required
                v-model="nameOnCard"
                :state="validation.nameOnCard"
              />
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                Card Number
                <span class="text-danger">*</span>
              </template>
              <div id="card-number" class="field empty"></div>
            </b-form-group>
            <b-row class="align-items-end">
              <b-col md="8">
                <b-form-group>
                  <template v-slot:label>
                    Expiration Date
                    <span class="text-danger">*</span>
                  </template>
                  <div id="card-expiry" class="field empty"></div>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <template v-slot:label>
                    CVC
                    <span class="text-danger">*</span>
                  </template>
                  <div id="card-cvc" class="field empty"></div>
                </b-form-group>
              </b-col>
            </b-row>
            <div v-if="showError">
              <p style="color: red;">
                {{ message }}
              </p>
            </div>
            <div v-if="showSuccess">
              <p>
                {{ message }}
              </p>
            </div>
            <p v-if="error500" style="color: red;">
              There has been an internal server error, please try again or contact with support team.
            </p>
          </div>
        </b-col>
        <b-col md="4">
          <div class="h-100 border-top border-top-md-0 border-md-left p-9">
            <h3 class="text-primary mb-7">Order Summary</h3>

            <div class="separator separator-solid separator-secondary my-7" />

            <div class="block-fee">
              <h6 class="text-primary mb-5">Application Fee</h6>

              <div class="table-responsive mb-0">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="font-weight-bolder border-top-0 pl-0">Amount</td>
                      <td class="text-right border-top-0 pr-0">
                        {{ moneyDollarForamter(applicationData.coordinator_fee) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="separator separator-solid separator-secondary my-7" />
            </div>

            <div class="block-fee" v-if="applicationData.background_check">
              <h6 class="text-primary mb-5">Background Fee</h6>

              <div class="table-responsive mb-0">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="font-weight-bolder border-top-0 pl-0">
                        Amount<span v-if="getLength > 1"> ($125.00 per applicant)</span>
                      </td>
                      <td class="text-right border-top-0 pr-0">
                        {{ moneyDollarForamter(applicationData.background_fee) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="separator separator-solid separator-secondary my-7" />
            </div>

            <div class="block-fee">
              <h6 class="text-primary mb-5">Convenience Fee</h6>

              <div class="table-responsive mb-0">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="font-weight-bolder border-top-0 pl-0">Amount</td>
                      <td class="text-right border-top-0 pr-0">
                        {{ moneyDollarForamter(applicationData.fixed_appexpress_fee) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="separator separator-solid separator-secondary my-7" />
            </div>

            <div class="block-fee" style="padding-bottom:7rem">
              <div class="table-responsive mb-0">
                <table class="table">
                  <tbody>
                    <tr class="font-weight-bolder font-size-h5 text-primary">
                      <td class="border-top-0 pl-0">Total</td>
                      <td class="text-right border-top-0 pr-0">{{ moneyDollarForamter(applicationData.full_fee) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="d-flex justify-content-between mt-5">
              <router-link to="/applications/applications-create" v-slot="{ href, navigate }">
                <b-btn variant="light-primary" class="font-weight-bold" @click="onClickReturn()">
                  Return
                </b-btn>
              </router-link>
              <b-btn
                variant="primary"
                class="font-weight-bold"
                :class="[isPending ? pendingClass : '']"
                @click="purchase()"
              >
                Place Order
              </b-btn>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-container class="px-0" v-if="showSuccessPage === true">
        <b-row>
          <b-col :md="8" class="mx-auto">
            <b-card class="card-custom" header-class="d-flex border-bottom-0">
              <template v-slot:header>
                <div class="card-title flex-column align-items-start">
                  #{{ transactionsData.id }}
                  <p class="text-primary">{{ transactionsData.attributes.paid_date }}</p>
                </div>
                <div class="card-toolbar d-flex">
                  <b-btn variant="primary" @click="printInvoice()">
                    <i class="flaticon2-print"></i>
                    Print
                  </b-btn>
                </div>
              </template>
              <div class="pb-8">
                <h2 class="font-weight-boldest">Your Order is Completed!</h2>
                <p class="lead">
                  Thank you for your order! Your order is being processed and will be processed as soon as possible.
                  <br />
                  You will receive an email confirmation when your order is completed.
                  <br />
                  Confirmation Will Be Sent to <strong>{{ getApplicant }}</strong>
                </p>
              </div>
              <div class="table-responsive mb-0">
                <table class="table">
                  <tbody>
                    <template v-for="header in invoiceHeaders">
                      <tr :key="header.key" class="border-bottom-0">
                        <td class="font-weight-boldest border-top-0 pl-0 py-4">{{ header.name }}</td>
                        <td class="border-top-0 pr-0 py-4 text-right">
                          <span
                            :class="[header.classes || 'd-block d-md-inline font-weight-bolder text-primary ml-md-10']"
                          >
                            {{ moneyDollarForamter(get(applicationData, header.key, 0)) }}
                          </span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
                <div class="card-toolbar d-flex justify-content-center">
                  <div class="d-inline-flex ">
                    <b-btn
                      variant="light-primary"
                      style="width:150px"
                      class="d-inline-block font-weight-bold"
                      @click="$router.push({ name: 'applications-index' })"
                    >
                      Return
                    </b-btn>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </KTWizardStepContent>
</template>
<script>
import { mapActions } from 'vuex';
let stripe = Stripe(process.env.VUE_APP_STRIPE_SECRET_TOKEN);
let elements = stripe.elements();
const elementStyles = {
  base: {
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    ':focus': {
      color: '#424770',
    },

    '::placeholder': {
      color: '#9BACC8',
    },

    ':focus::placeholder': {
      color: '#CFD7DF',
    },
  },
  invalid: {
    color: 'red',
    ':focus': {
      color: 'red',
    },
    '::placeholder': {
      color: 'red',
    },
  },
};

const elementClasses = {
  focus: 'focus',
  empty: 'empty',
  invalid: 'invalid',
};

import ApiService from '@/helpers/api.service';

export default {
  name: 'applications-payment',
  props: {
    applicationData: {
      type: Object,
      default: () => ({}),
    },
    applicantData: {
      type: Object,
      default: () => ({}),
    },
    appId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      divide: 100,
      transactionsData: null,
      showSuccessPage: null,
      isSameBillingAddress: true,
      isAddressSame: true,
      stateSelected: false,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      nameOnCard: '',
      validation: {
        nameOnCard: null,
      },
      error500: false,
      showError: false,
      showSuccess: false,
      message: '',
      additionalAddress: {
        street: '',
        suite: '',
        city: '',
        state: '',
        zip: '',
      },
      additionalAddressRequire: {
        street: false,
        suite: false,
        city: false,
        state: false,
        zip: false,
      },
    };
  },
  computed: {
    getApplicant() {
      if (this.applicationData.applicants.data && this.applicationData.applicants.data.length) {
        return `${this.applicationData.applicants.data[0].attributes.first_name} ${this.applicationData.applicants.data[0].attributes.last_name}`;
      }
      return 1;
    },
    getLength() {
      return this.applicationData.applicants.data ? this.applicationData.applicants.data.length : 0;
    },
    fixed_appexpress_fee() {
      return 5000;
    },
    stripe_fee() {
      return (
        (this.getCoordinatorFee + this.cooperativeFee + this.managing_agent_fee + this.background_fee) * (3.0 / 100) +
        30
      );
    },
    getCoordinatorFee() {
      return this.applicationData.fee || 0;
    },
    cooperative() {
      return this.applicationData.cooperative.data;
    },

    transactionData() {
      return this.applicationData.transactions.data;
    },
    invoiceHeaders() {
      let data = [
        { name: 'Application fee', key: 'coordinator_fee' },
        { name: 'Background fee', key: 'background_fee' },
        { name: 'Convenience fee', key: 'fixed_appexpress_fee' },
        // { name: 'Cooperative fee', key: 'cooperative_fee' },
        // { name: 'Managing Agent fee', key: 'managing_agent_fee' },
        // { name: 'Stripe fee', key: 'stripe_fee' },
        { name: 'Total', key: 'full_fee', classes: 'd-block d-md-inline font-size-h3 ml-10 text-primary' },
      ];

      if (!this.applicationData.background_fee) data = data.filter((i) => i.key != 'background_fee');

      return data;
    },
  },
  methods: {
    ...mapActions({
      generatePdf: 'applications/generatePdf',
      generateFullPdf: 'applications/generateFullPdf',
    }),
    printInvoice() {
      window.print();
    },
    getTransaction() {
      if (this.transactionData.length) {
        this.transactionsData = this.transactionData[this.transactionData.length - 1];
        if (this.transactionsData.attributes.status === 'Succeeded') {
          this.showSuccessPage = true;
        }
      } else {
        const fD = new FormData();
        fD.append('transaction[application_id]', this.appId);
        ApiService.post('/transactions', fD)
          .then((res) => {
            this.transactionsData = res.data.data;
          })
          .catch((err) => {
            this.error500 = true;
          });
      }
    },
    onDestroyElements() {
      if (this.cardNumber) this.cardNumber.destroy();
      if (this.cardExpiry) this.cardExpiry.destroy();
      if (this.cardCvc) this.cardCvc.destroy();
    },
    onClickReturn() {
      this.onDestroyElements();
      this.onCloseAndSave();
    },
    createCard() {
      this.cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardNumber.mount('#card-number');

      this.cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardExpiry.mount('#card-expiry');

      this.cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardCvc.mount('#card-cvc');
    },
    setAddressToSame() {
      this.isAddressSame = true;
    },

    setAddressToOther() {
      this.isAddressSame = false;
    },
    async onCloseAndSave() {
      if (this.showSuccessPage && this.transactionData.length) {
        this.transactionsData = this.transactionData[this.transactionData.length - 1];
        if (this.transactionsData.attributes.status === 'Succeeded') {
          const formData = new FormData();
          formData.append('application[step_status]', '5');
          await ApiService.put(`applications/${this.appId}`, formData);
        }
      }
      this.$emit('onSaveAndClose');
    },
    purchase() {
      this.showError = false;
      this.message = '';
      if (this.nameOnCard.length === 0) {
        this.validation.nameOnCard = false;
        return;
      }

      const applicantData = this.applicantData || {};

      this.isPending = true;
      const clientSecret = this.transactionsData.attributes.stripe_client_secret;

      stripe
        .handleCardPayment(clientSecret, this.cardNumber)
        .then((result) => {
          if (result.error) {
            this.message = result.error.message;
            this.showError = true;
          } else {
            this.successData = result;
            this.showSuccessPage = true;
            const formData = new FormData();
            formData.append('application[step_status]', '6');
            ApiService.put(`applications/${this.appId}`, formData);
            this.generatePdfDocs();
          }
        })
        .then(() => {
          this.isPending = false;
        });
    },
    async generatePdfDocs() {
      await this.generatePdf(this.appId);
      await this.generateFullPdf(this.appId);
    },
  },
  beforeDestroy() {
    this.onDestroyElements();
  },
  mounted() {
    this.showSuccessPage = false;
    this.$nextTick(() => {
      if (!this.showSuccessPage) {
        this.createCard();
      }
    });
    this.getTransaction();
  },
  watch: {
    nameOnCard: function(val) {
      this.validation.nameOnCard = val ? val !== '' : null;
    },
    isSameBillingAddress(val) {
      if (val) {
        this.additionalAddress.street = '';
        this.additionalAddress.suite = '';
        this.additionalAddress.city = '';
        this.additionalAddress.state = '';
        this.additionalAddress.zip = '';
      }
    },
    'additionalAddress.street': function(val) {
      this.additionalAddressRequire.street = val !== '';
    },
    'additionalAddress.suite': function(val) {
      this.additionalAddressRequire.suite = val !== '';
    },
    'additionalAddress.city': function(val) {
      this.additionalAddressRequire.city = val !== '';
    },
    'additionalAddress.state': function(val) {
      this.additionalAddressRequire.state = val !== '';
    },
    'additionalAddress.zip': function(val) {
      this.additionalAddressRequire.zip = val !== '';
    },
  },
};
</script>
<style scoped>
.w-lg-600px {
  width: 100% !important;
}

.fieldset {
  margin: 0 15px 30px;
  padding: 0;
  border-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.field {
  padding: 16.5px;
  background-color: #f6fbff;
  border-radius: 5px;
  width: 100%;
}

.field.half-width {
  width: calc(50% - (5px / 2));
}

.field.third-width {
  width: calc(33% - (5px / 3));
}

.field + .field {
  margin-top: 6px;
}

.field.focus,
.field:focus {
  color: #424770;
  background-color: #f6f9fc;
}

.field.invalid {
  border-color: red;
}

.field.invalid.focus {
  background-color: #f6f9fc;
}

.field.focus::-webkit-input-placeholder,
.field:focus::-webkit-input-placeholder {
  color: #cfd7df;
}

.field.focus::-moz-placeholder,
.field:focus::-moz-placeholder {
  color: #cfd7df;
}

.field.focus:-ms-input-placeholder,
.field:focus:-ms-input-placeholder {
  color: #cfd7df;
}

input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

input {
  color: #fff;
}

input::-webkit-input-placeholder {
  color: #9bacc8;
}

input::-moz-placeholder {
  color: #9bacc8;
}

input:-ms-input-placeholder {
  color: #9bacc8;
}

button {
  display: block;
  width: calc(100% - 30px);
  height: 40px;
  margin: 0 15px;
  background-color: #fcd669;
  border-radius: 20px;
  color: #525f7f;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

button:active {
  background-color: #f5be58;
}

.error svg .base {
  fill: #fa755a;
}

.error svg .glyph {
  fill: #fff;
}

.error .message {
  color: #fff;
}

.success .icon .border {
  stroke: #fcd669;
}

.success .icon .checkmark {
  stroke: #fff;
}

.success .title {
  color: #fff;
}

.success .message {
  color: #9cabc8;
}

.success .reset path {
  fill: #fff;
}
.card-custom {
  box-shadow: none;
}
</style>
