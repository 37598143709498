<template>
  <KTWizardStepContent :step-number="3" title="Financial Information">
    <template v-slot:save-btn>
      <b-btn
        v-cs-loading="saveAndExitSubmiting"
        variant="light-primary"
        size="lg"
        :disabled="footerSubmiting"
        @click="saveAndExit"
      >
        Save and Exit
      </b-btn>
    </template>
    <template v-slot:nav>
      <KTWizardStepContentNavList>
        <template v-for="(kstep, kindex) in steps">
          <KTWizardStepContentNavItem
            :key="kindex"
            :order-number="kindex + 1"
            :title="kstep.title"
            :is-checked="step > kindex"
            :is-current="step == kindex"
          />
        </template>
      </KTWizardStepContentNavList>
    </template>
    <template v-slot:body>
      <total-assets-form v-if="step === 0" :applicants="applicants" :application-id="appId" :v="$v" />
      <total-liabitilites-form v-if="step === 1" :applicants="applicants" :application-id="appId" :v="$v" />
      <annual-income-form v-if="step === 2" :v="$v" />
      <expenses-form v-if="step === 3" :applicants="applicants" :application-id="appId" :v="$v" />
      <other-info-form v-if="step === 4" :applicants="applicants" :application-id="appId" :v="$v" />
    </template>
    <template v-slot:footer>
      <b-btn
        v-if="step > 0"
        :disabled="saveAndExitSubmiting"
        variant="light-primary"
        class="font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3"
        @click="back"
      >
        <span class="svg-icon svg-icon-md mr-2">
          <inline-svg src="/media/svg/icons/Navigation/Left-2.svg" />
        </span>
        Back
      </b-btn>

      <b-btn
        v-cs-loading="footerSubmiting"
        variant="primary"
        class="font-weight-bolder font-size-h6 py-4 ml-auto"
        :disabled="saveAndExitSubmiting"
        :class="{ 'pl-8 pr-4': !isLastStep, 'px-8': isLastStep }"
        @click="next"
      >
        {{ isLastStep ? 'Finish' : 'Next' }}
        <span v-if="!isLastStep" class="svg-icon svg-icon-md ml-2">
          <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
        </span>
      </b-btn>
    </template>
  </KTWizardStepContent>
</template>

<script>
import { required, email, integer, maxLength, minLength, minValue } from 'vuelidate/lib/validators';
import { adminUserWithJoint, adminUser } from '@/helpers/validations';
import ApiService from '@/helpers/api.service';
import TotalAssetsForm from './TotalAssetsForm';
import TotalLiabitilitesForm from './TotalLiabitilitesForm';
import AnnualIncomeForm from './AnnualIncomeForm';
import ExpensesForm from './ExpensesForm';
import OtherInfoForm from './OtherInfoForm';

export default {
  name: 'Main',
  components: {
    OtherInfoForm,
    ExpensesForm,
    AnnualIncomeForm,
    TotalLiabitilitesForm,
    TotalAssetsForm,
  },
  props: {
    appId: {
      type: [String, Number],
      required: true,
    },
    applicant: {
      type: Object,
      default: () => {},
    },
  },
  validations: {
    applicantForm: {
      cashes: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          amount: { required },
        },
      },
      certificate_deposites: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          amount: { required },
        },
      },
      securities: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          amount: { required },
        },
      },
      real_estates: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          percentage: { required },
          type_of_property: { required },
          location: { required },
          estimated_value: { required },
          mortgage_balance: { required },
        },
      },
      vehicles: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          make: { required },
          model: { required },
          year: { required },
          loan_balance: { required },
          estimated_value: { required },
        },
      },
      individual_retirement_accounts: {
        $each: {
          admin_user_id: { adminUser },
          ira_type: { required },
          balance: { required },
        },
      },
      life_insurances: {
        $each: {
          admin_user_id: { adminUser },
          name: { required },
          life_insurance_type: { required },
          benefit_amount: { required },
          beneficiary: { required },
        },
      },
      businesses: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          name: { required },
          business_type: { required },
          percentage: { required },
          date_of_formation: { required },
          date_of_termination: { required },
          estimated_value: { required },
          estimated_amount_of_liabilities: { required },
          estimated_amount_of_assets: { required },
        },
      },
      properties: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          description: { required },
          estimated_value: { required },
        },
      },
    },
    applicantForm2: {
      installment_debts: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          balance: { required },
          monthly_payments: { required },
        },
      },
      credit_card_debts: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          balance: { required },
          monthly_payments: { required },
        },
      },
      mortgages: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          balance: { required },
          monthly_payments: { required },
        },
      },
      automobiles: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          balance: { required },
          monthly_payments: { required },
        },
      },
      other_liabilities: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          balance: { required },
          monthly_payments: { required },
        },
      },
    },
    applicantForm3: {
      $each: {
        income: { required, minValue: 0 },
        bonus: { required, minValue: 0 },
        securities_amount: { required, minValue: 0 },
        social_security: { required, minValue: 0 },
        k_income: { required, minValue: 0 },
        dividents: { required, minValue: 0 },
        investment_properties: { required, minValue: 0 },
        other_incomes: { required, minValue: 0 },
      },
    },
    applicantForm4: {
      vehicle_leases: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          amount: { required },
        },
      },
      personal_loans: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          amount: { required },
        },
      },
      student_loans: {
        $each: {
          admin_user_id: { adminUser },
          amount: { required },
        },
      },
      mortgage_expenses: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          amount: { required },
        },
      },
      alimonies: {
        $each: {
          admin_user_id: { adminUser },
          amount: { required },
        },
      },
      child_supports: {
        $each: {
          admin_user_id: { adminUser },
          amount: { required },
        },
      },
      other_expenses: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          amount: { required },
        },
      },
    },
    applicantForm5: {
      outstanding_judgments: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          explanation: { required },
        },
      },
      personal_bankruptcies: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          result: { required },
          explanation: { required },
        },
      },
      business_bankruptcies: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          result: { required },
          explanation: { required },
        },
      },
      property_forcloseds: {
        $each: {
          admin_user_id: { adminUserWithJoint },
          address: { required },
          explanation: { required },
        },
      },
    },
  },
  data() {
    return {
      saveAndExitSubmiting: false,
      footerSubmiting: false,
      step: 0,
      steps: [
        {
          title: 'Total Assets',
          description: 'Applicants',
        },
        {
          title: 'Total Liabilities',
          description: 'Personal Information for applicant',
        },
        {
          title: 'Annual Income',
          description: 'Educational Information for applicant',
        },
        {
          title: 'Monthly Expenses',
          description: 'Residential Information for applicant',
        },
        {
          title: 'Other Financial Information',
          description: 'Employment Information for applicant',
        },
      ],
      applicantForm: {},
      applicantForm2: {},
      applicantForm3: {},
      applicantForm4: {},
      applicantForm5: {},
    };
  },
  watch:{
    step:{
      handler(){
        $(".wizard-custom-content-body").scrollTop(0);
      }
    }
  },
  computed: {
    isLastStep() {
      return this.step + 1 == this.steps.length;
    },
    applicants() {
      return this.applicant.applicants.data.map((applicant) => {
        return {
          id: applicant.id,
          ...applicant.attributes,
        };
      });
    },
  },
  methods: {
    initForms(form) {
      this.applicantForm = {
        cashes: [],
        certificate_deposites: [],
        securities: [],
        real_estates: [],
        vehicles: [],
        individual_retirement_accounts: [],
        life_insurances: [],
        businesses: [],
        properties: [],
      };

      this.applicantForm2 = {
        installment_debts: [],
        credit_card_debts: [],
        mortgages: [],
        automobiles: [],
        other_liabilities: [],
      };

      this.applicantForm3 = form.applicants.data.map((applicant) => {
        return {
          income: applicant.attributes.income || '0',
          bonus: applicant.attributes.bonus || '0',
          securities_amount: applicant.attributes.securities_amount || '0',
          social_security: applicant.attributes.social_security || '0',
          k_income: applicant.attributes.k_income || '0',
          dividents: applicant.attributes.dividents || '0',
          investment_properties: applicant.attributes.investment_properties || '0',
          other_incomes: applicant.attributes.other_incomes || '0',
        };
      });

      this.applicantForm4 = {
        vehicle_leases: [],
        personal_loans: [],
        student_loans: [],
        mortgage_expenses: [],
        alimonies: [],
        child_supports: [],
        other_expenses: [],
      };

      this.applicantForm5 = {
        outstanding_judgments: [],
        personal_bankruptcies: [],
        business_bankruptcies: [],
        property_forcloseds: [],
      };
    },
    saveAndExit() {
      
      this.saveAndExitSubmiting = true;
      this.next();
    },
    updateForm1(form) {
      const promises = [];

      Object.keys(form).forEach((key) => {
        form[key].forEach((applicant) => {
          let promise;
          if (applicant.id) {
            promise = ApiService.put(`${key}/${applicant.id}`, { application_id: this.appId, ...applicant });
          } else {
            promise = ApiService.post(`${key}`, { application_id: this.appId, ...applicant }).then((res) => {
              applicant.id = res.data.data.id;
            });
          }
          promises.push(promise);
        });
      });

      return Promise.all(promises);
    },
    updateForm3() {
      const promises = [];

      this.$v.applicantForm3.$model.forEach((applicant, index) => {
        const id = this.applicant.applicants.data[index].id;
        const formData = new FormData();

        formData.append(`admin_user[id]`, id);
        Object.keys(applicant).forEach((key) => {
          formData.append(`admin_user[${key}]`, applicant[key]);
        });

        const promise = ApiService.put(`applicants/${id}`, formData);
        promises.push(promise);
      });

      return Promise.all(promises);
    },
    async next() {
      if (this.step == 0) {
        if (!this.$v.applicantForm.$invalid) {
          this.footerSubmiting = true;
          this.updateForm1(this.$v.applicantForm.$model)
            .then(() => {
              this.footerSubmiting = false;
              if (this.saveAndExitSubmiting) {
                this.$emit('close');
              }
              return (this.step += 1);
            })
            .catch(() => {
              this.footerSubmiting = false;
              this.saveAndExitSubmiting = false;
            });
        } else {
          this.alertValidationFields(this.$v.applicantForm, []);
          this.$v.applicantForm.$touch();
          this.saveAndExitSubmiting = false;
        }
      } else if (this.step == 1) {
        if (!this.$v.applicantForm2.$invalid) {
          this.footerSubmiting = true;
          this.updateForm1(this.$v.applicantForm2.$model)
            .then(() => {
              this.footerSubmiting = false;
              if (this.saveAndExitSubmiting) {
                this.$emit('close');
              }
              return (this.step += 1);
            })
            .catch(() => {
              this.footerSubmiting = false;
              this.saveAndExitSubmiting = false;
            });
        } else {
          this.alertValidationFields(this.$v.applicantForm2, []);
          this.$v.applicantForm2.$touch();
          this.saveAndExitSubmiting = false;
        }
      } else if (this.step == 2) {
        if (!this.$v.applicantForm3.$invalid) {
          this.footerSubmiting = true;

          this.updateForm3()
            .then(() => {
              this.footerSubmiting = false;
              if (this.saveAndExitSubmiting) {
                this.$emit('close');
              }
              return (this.step += 1);
            })
            .catch(() => {
              this.footerSubmiting = false;
              this.saveAndExitSubmiting = false;
            });
        } else {
          this.alertValidationFields(this.$v.applicantForm3, []);
          this.$v.applicantForm3.$touch();
          this.saveAndExitSubmiting = false;
        }
      } else if (this.step == 3) {
        if (!this.$v.applicantForm4.$invalid) {
          this.footerSubmiting = true;
          this.updateForm1(this.$v.applicantForm4.$model)
            .then(() => {
              this.footerSubmiting = false;
              if (this.saveAndExitSubmiting) {
                this.$emit('close');
              }
              return (this.step += 1);
            })
            .catch(() => {
              this.footerSubmiting = false;
              this.saveAndExitSubmiting = false;
            });
        } else {
          this.alertValidationFields(this.$v.applicantForm4, []);
          this.$v.applicantForm4.$touch();
          this.saveAndExitSubmiting = false;
        }
      }

      if (this.isLastStep) {
        if (!this.$v.applicantForm5.$invalid) {
          this.footerSubmiting = true;
          this.updateForm1(this.$v.applicantForm5.$model)
            .then(() => {
              this.footerSubmiting = false;
              this.$emit('finish');
            })
            .catch(() => {
              this.footerSubmiting = false;
              this.saveAndExitSubmiting = false;
            });
        } else {
          this.alertValidationFields(this.$v.applicantForm5, []);
          this.$v.applicantForm5.$touch();
          this.saveAndExitSubmiting = false;
        }
      }
    },
    back() {
      this.step -= 1;
    },
  },
  created() {
    this.initForms(this.applicant);
  },
};
</script>

<style scoped></style>
